/**
 * Service for interacting with curriculum tracks in Firestore, extends BaseFirestoreService
 *
 * @since 0.1.15
 */

import { Injectable } from '@angular/core';

// import * as firebase from 'firebase';
import { AngularFirestore } from '@angular/fire/compat/firestore';

// import { Subject } from 'rxjs';
//import { map, take } from 'rxjs/operators';

import { ApiFirestoreService } from '@app/_core/services/api-firestore/api-firestore.service';

import { QueryVars } from '@app/_core/models';
import { CurriculumTrack } from '@app/_core/models/firestore';

@Injectable({
  providedIn: 'root'
})
export class CurriculumTrackService extends ApiFirestoreService<CurriculumTrack> {

  constructor(afs: AngularFirestore) {
    super('curriculumTracks', afs);
  }

  /**
   * Get all tracks in the order of dateCreated
   *
   * @since 0.1.15
   */
  getTracks(): Promise<CurriculumTrack[]> {
    const query: QueryVars = {
      order: 'dateCreated',
      dir: 'asc'
    };

    return this.getQueryData(query);
  }
}
