import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';

const children = [
    { path: '', loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsModule) },
    { path: 'account', loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule) },
    { path: 'compare', loadChildren: () => import('./pages/compare/compare.module').then(m => m.CompareModule) },
    { path: 'wishlist', loadChildren: () => import('./pages/wishlist/wishlist.module').then(m => m.WishlistModule) },
    { path: 'cart', loadChildren: () => import('./pages/cart/cart.module').then(m => m.CartModule) },
    { path: 'checkout', loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutModule) },
    { path: 'contact', loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule) },
    { path: 'brands', loadChildren: () => import('./pages/brands/brands.module').then(m => m.BrandsModule) },
    { path: 'products', loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsModule) },
    { path: 'sign-in', loadChildren: () => import('./pages/sign-in/sign-in.module').then(m => m.SignInModule) },
    { path: 'register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule) },
];

export const routes: Routes = [
    {
        path: '',
        component: PagesComponent,
        children: children,
        data: {}
    },
    { path: 'landing', loadChildren: () => import('./amptify/amptify.module').then(m => m.AmptifyModule)},
    { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
    { path: '**', component: NotFoundComponent }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }