<mat-chip-listbox *ngIf="product.discount">
    <mat-chip-option color="warn" selected="true">{{product.discount}}% OFF</mat-chip-option>
</mat-chip-listbox>
<a (click)="navigate(product._id, product.name)" [ngClass]="view !== '3by2' ? 'image-link img-height' : 'image-link grid-img-height'">
    <img [src]="selectedImage" class="swiper-lazy" (error)="onImgError($event)" />
</a>
<a (click)="navigate(product._id, product.name)" class="title text-left link">
    {{product.name}}
</a>
<div fxLayout="column" fxLayoutAlign="center start">
  <p class="new-price">{{product.price / 100 | currency}}</p>
</div>
<div fxLayout="row" fxLayoutAlign="space-between center" class="prices">
  <div *ngIf="variantType" class="variant-select">
    <div *ngFor="let variant of product[variantType]" class="variant {{ variantType }} {{ getVariantCss(variant) }}" [class.selected]="variant.selected" (click)="selectVariant(variant)">
      {{ getVariantDisplay(variant) }}
    </div>
  </div>
</div>
<div class="divider mt-2"></div>