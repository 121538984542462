<div class="payment-methods">
	<mat-card *ngIf="tokenizedCard?.token" class="card-detail" [class.selected]="selectedPrimary === 'card'">
		<p class="current-card"><span class="type">{{ tokenizedCard.type }}</span> ending in <span class="last-four primary-color">{{ tokenizedCard.lastFour }}</span></p>

		<div class="actions">
			<button mat-raised-button color="accent" (click)="startCollectJS()">Change Card</button>
			<button *ngIf="selectedPrimary !== 'card'" mat-raised-button color="accent" (click)="selectPrimary('card')">Make Default</button>
		</div>

		<div class="top-right">
			<p *ngIf="selectedPrimary === 'card'" class="text-muted">DEFAULT</p>
			<mat-icon color="primary">credit_card</mat-icon>
		</div>

		<button mat-button class="mat-small delete-payment" color="warn" (click)="deletePayment('card')">Delete</button>
	</mat-card>

	<button *ngIf="!tokenizedCard?.token" mat-raised-button color="accent" (click)="startCollectJS()">{{ buttonText }}</button>

	<!-- <p>Payment Methods Table</p>
	<p>PAYMENT METHOD	-	BILLING ADDRESS	-	EXPIRATION DATE	-	PRODUCTS	-	ACTIONS</p>

	<button mat-raised-button color="accent" (click)="openAddress()" [disabled]="!company">Add an address</button> -->
	
	<span *ngIf="declinedCards && declinedCards.length">
		<h2 *ngIf="declinedCards && declinedCards.length" class="declined-cards-heading">Declined Cards</h2>
		<mat-card appearance="outlined" *ngFor="let card of declinedCards" class="declined-cards">
			<span *ngIf="card.type" class="type">{{card.type}}</span> ending in
			<span *ngIf="card.lastFour" class="last-four primary-color"> {{card.lastFour}}</span>
			<p></p>
			<p *ngIf="card.error">
				<span class="type">Error :</span>
				{{card.error}}
			</p>
		</mat-card>
	</span>

</div>
