// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url: '',
  defaultSlug: '/shop',
  apiURL: 'https://us-central1-amptify-ionic-firebase-dev.cloudfunctions.net',
  firebase: {
    apiKey: "AIzaSyDPi2nhSjI_ZubQmqIEvqwRyQJYR7JotsI",
    authDomain: "amptify-ionic-firebase-dev.firebaseapp.com",
    databaseURL: "https://amptify-ionic-firebase-dev-default-rtdb.firebaseio.com",
    projectId: "amptify-ionic-firebase-dev",
    storageBucket: "amptify-ionic-firebase-dev.appspot.com",
    messagingSenderId: "419183338005",
    appId: "1:419183338005:web:1add2d663e628de5d0a41c",
    measurementId: "G-NTYGKBMYCV"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
