import { Injectable } from '@angular/core';

import firebase from 'firebase/compat/app';

import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ApiFirestoreService } from '@app/_core/services/api-firestore/api-firestore.service';
import { MarketplaceOrder } from '@app/_core/models/firestore';

@Injectable({
  providedIn: 'root'
})
export class MarketplaceOrderService extends ApiFirestoreService<MarketplaceOrder> {

  constructor(afs: AngularFirestore) {
  	super('marketplaceOrders', afs);
  }

  async requestUpdate(order_id: string) {
    await this.update(order_id, {requestUpdate: firebase.firestore.FieldValue.serverTimestamp()});
  }
}
