import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { SponsoredUserDiscount } from '@app/_core/models/firestore/discounts-networks';
import { ApiFirestoreService } from '@app/_core/services/api-firestore/api-firestore.service';

@Injectable({
    providedIn: 'root'
})
export class SponsoredUserDiscountService extends ApiFirestoreService<SponsoredUserDiscount> {

    constructor(afs: AngularFirestore,
    ) {
        super('sponsoredUserDiscounts', afs);
    }

}