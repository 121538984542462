import { Component, Inject, OnInit, OnDestroy, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AmptifyCard } from "@app/_core/models/payments";
import { Account, CompanyMarketplace } from '@app/_core/models';

import { AccountBillingService, AccountService, CompanyMarketplaceService } from '@app/_core/services/firestore';

@Component({
  selector: 'amptify-payment-method-form',
  templateUrl: './payment-method-form.component.html',
  styleUrls: ['./payment-method-form.component.scss']
})
export class PaymentMethodFormComponent implements OnInit, OnDestroy {
  private _tokenizedCard: AmptifyCard;
  private _onDestroy = new Subject();

  submittingPayment = false;

  currentUser: Account;

  @Input()
  set tokenizedCard(card: AmptifyCard) {
    this._tokenizedCard = card;
    this.ref.markForCheck();
  }

  private captureSubject = new Subject<AmptifyCard>();
  @Output() cardCapture = new EventEmitter<AmptifyCard>();
  @Output() observeCapture = new EventEmitter<Subject<AmptifyCard>>();
  @Output() partiallyValid = new EventEmitter<boolean>();

  billingForm: FormGroup;
  stateOptions = [];
  cardType = 'CARD';
  achType = 'ACH';
  paymentType = this.cardType;
  collectJsFields = {};
  private collectValidation = new Subject();

  paymentError: string;

  private companyData: CompanyMarketplace;
  public useExistingCard: boolean;
  private newCard: AmptifyCard;

  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    private accountBillingService: AccountBillingService,
    private companyMarketplaceService: CompanyMarketplaceService,
    private ref: ChangeDetectorRef
  ) {
    this.companyData = this.companyMarketplaceService.getMarketplaceSettings();

    this.accountService.currentAccountInfo
      .subscribe(account => {
        this.currentUser = account;
        if (this.currentUser)
          this.getAccountBillingInfo();
      })
  }

  ngOnInit(): void {
    this.stateOptions = ['']//states.filter(s => !s.territory);

    this.billingForm = this.fb.group({
      cardName: ['', Validators.required]
    });

    this.loadCollectJs();
    this.validateCollect();
  }

  ngOnDestroy(): void {
    this._onDestroy.next(null);
    this._onDestroy.complete();
  }

  get billingCardName() {return this.billingForm.get('cardName');}
  get tokenizedCard(): AmptifyCard { return this._tokenizedCard; }

  isCardType() {
      return this.paymentType === this.cardType;
  }

  isAchType() {
      return this.paymentType === this.achType;
  }

  loadCollectJs() {
    (<any>window).CollectJS.configure({
      'variant': 'inline',
      'styleSniffer': 'true',
      'googleFont': 'Montserrat:400',
      'customCss': {
        'border-width': '1px',
        'border-color': 'rgba(0, 0, 0, 0.38)',
        'border-radius': '4px',
        'font-family': 'Arial',
        'padding': '16px',
        'height': '56px',
        'font-size': '15px',
        'background': 'transparent'
      },
      'invalidCss': {
        'color': '#F44336',
        'border-color': '#F44336',
        'border-width': '2px',
        'border-style': 'solid'
      },
      'validCss': {
        'color': 'black'
      },
      'placeholderCss': {
        'color': '#757575'
      },
      'focusCss': {
        'border-color': this.companyData.primaryHex,
        'border-width': '2px',
        'border-style': 'solid'
      },
      'fields': {
        'checkaccount': {
          'selector': '#check-acct',
          'title': 'Account Number',
          'placeholder': '0000000000'
        },
        'checkaba': {
          'selector': '#check-aba',
          'title': 'Routing Number',
          'placeholder': '000000000'
        },
        'checkname': {
          'selector': '#check-name',
          'title': 'Name on Checking Account',
          'placeholder': 'Name on Checking Account'
        },
        'ccnumber': {
          'selector': '#cc-number',
          'title': 'Card Number',
          'placeholder': '1234 1234 1234 1234'
        },
        'ccexp': {
          'selector': '#cc-exp',
          'title': 'Card Expiration',
          'placeholder': 'MM / YY'
        },
        'cvv': {
          'display': 'show',
          'selector': '#cvv',
          'title': 'CVV Code',
          'placeholder': 'CVV'
        }
      },
      'validationCallback': (field, status, message) => {
        // console.log('status:', status);
        // console.log('field:', field);
        // console.log('message:', message);
        this.collectJsFields[field].valid = status;
        if (status) {
          message = field + ' is now OK: ' + message;
        } else {
          message = field + ' is now Invalid: ' + message;
        }
        this.collectValidation.next(this.collectJsFields);
        // console.log('collectJsFields:', this.collectJsFields);
        // TODOLATER MMD - how to show messages PER FIELD??
      },
      'timeoutDuration': 0,
      'timeoutCallback': function() {
        this.submittingPayment = false;
        this.snackBar.open('The tokenization didn\'t respond in the expected timeframe.  This could be due to an invalid or incomplete field or poor connectivity', null, {duration: 3000});
        this.captureSubject.next({timeout: 'collectjs timeout'});
        console.log('The tokenization didn\'t respond in the expected timeframe.  This could be due to an invalid or incomplete field or poor connectivity');
      },
      'fieldsAvailableCallback': () => {
        if (this.isAchType()) {
          this.collectJsFields = {
            checkaccount: {
              valid: false
            },
            checkaba: {
              valid: false
            },
            checkname: {
              valid: false
            }
          };
        } else {
          this.collectJsFields = {
            ccnumber: {
              valid: false
            },
            ccexp: {
              valid: false
            },
            cvv: {
              valid: false
            }
          };
        }
      },
      'callback': (response) => {
        // alert(response.token);

        const createData = {
          payment_token: response.token,
          payment: this.isAchType() ? 'check' : 'creditcard',
          organizationId: '',//this.data.organizationId,
          subscriptionId: '',//this.data.subscriptionId,
          company: '',// this.data.organizationName,
          email: '',//this.data.userEmail,
          country: 'US',
          ...this.billingForm.value
        };

        if (this.isAchType()) {
          createData['check'] = response.check;
          delete createData.check.hash;
        } else if (this.isCardType()) {
          createData['card'] = response.card;
          delete createData.card.hash;
        }

        this.newCard = {
          type: response.card.type,
          lastFour: response.card.number.slice(-4),
          token: response.token
        };
        this.captureSubject.next(this.newCard);
        this.cardCapture.emit(this.newCard);
        this.submittingPayment = false;
        this.ref.detectChanges();
        // this.billingInfoService.createBillingInfoCustomer(createData).subscribe((newRes: any) => {
        //   console.log('newRes:', newRes);
        //   this.submittingPayment = false;
        //   if (newRes.responseCode === '100') {
        //     console.log('success', newRes);
        //   } else {
        //     this.paymentError = newRes.responseText;
        //     console.log(this.paymentError);
        //     // this.errorDialog.open('Unexpected Error', `The following error occurred: ${this.paymentError}. Please try again later.`).subscribe(result => {
        //     //   console.log(result);
        //     // });
        //   }
        // });
      }
    });
  }

  private validateCollect() {
    this.collectValidation.pipe(takeUntil(this._onDestroy)).subscribe((collect: any) => {
      if (this.isAchType()) {
        // This isn't being used
      } else {
        this.captureSubject.next(collect);

        if (collect?.ccnumber?.valid && collect?.ccexp?.valid && collect?.cvv?.valid) {
          this.enterPaymentInfo(null);
        } else if (collect?.ccnumber?.valid && (collect?.ccexp?.valid || collect?.cvv?.valid)) {
          this.observeCapture.emit(this.captureSubject);
          this.partiallyValid.emit(true);
        } else {
          this.partiallyValid.emit(false);
        }
      }
    })
  }

  enterPaymentInfo($event) {
    if (this.canSubmitCollectFields()) {
      this.submittingPayment = true;
      this.paymentError = undefined;
      this.ref.detectChanges();
      (<any>window).CollectJS.startPaymentRequest($event);
    } else {
      console.log(`Invalid ${this.isAchType() ? 'ACH' : 'Card'} Information`, `One or more ${this.isAchType() ? 'ACH' : 'Card'} information fields are empty or invalid. Please fix and try again.`);
      // this.errorDialog.open(`Invalid ${this.isAchType() ? 'ACH' : 'Card'} Information`, `One or more ${this.isAchType() ? 'ACH' : 'Card'} information fields are empty or invalid. Please fix and try again.`).subscribe(result => {
      //     // console.log(result);
      // });
    }
  }

  canSubmitCollectFields() {
    let collectFieldsValid = true;
    for (const field in this.collectJsFields) {
      collectFieldsValid = collectFieldsValid && this.collectJsFields[field].valid;
    }

    return collectFieldsValid;
  }

  private async getAccountBillingInfo() {
    const billingInfo = await this.accountBillingService.getData(this.currentUser?._id);
    if (billingInfo) {
      const { type = '', lastFour = '', token = '' } = billingInfo;
      this.tokenizedCard = { type, lastFour, token };
    }
    //this.cardCapture.emit(this.tokenizedCard);

    return;
  };

  private async saveCardInfo(data: AmptifyCard, isdelete: boolean = false) {
    await this.accountBillingService.update(this.currentUser?._id, {...data, customerChange: true});
    await this.getAccountBillingInfo();
    this.submittingPayment = false;
  }

  public cardSelection(event: any) {
    this.useExistingCard = event.checked;

    this.cardCapture.emit(event.checked ? this.tokenizedCard : this.newCard || null);
  }
}