export const ORDER_STATUS: string[] = [
	'payment-pending',
	'processing',
	'payment-error',
	'awaiting-fulfillment',
	'awaiting-shipping',
	'awaiting-pickup',
	'partially-shipped',
	'shipped',
	'completed',
	'canceled',
	'declined',
	'disputed',
	'refund-requested',
	'refunded',
	'partially-refunded',
	'return-requested',
	'return-refunded',
	'return-partially-refunded',
	'returned'
];

export const SINGLE_SHIPPING_COST: number = 495; // This is in cents

export const SHIPPING_CARRIERS: { name: string; value: string }[] = [
	{ name: 'UPS', value: 'ups' },
	{ name: 'FedEx', value: 'fedex' },
	{ name: 'USPS', value: 'usps' },
	{ name: 'DHL', value: 'dhl' }
];

export const ROLES = {
  FREQUENT_COMMUNICATION_PARTNER: 'JuBMom9cOfqCs4eaS2Yq',
  GROUP_COACH: 'bWm6uqbBKcWZV1ioicSm',
  PARTICIPANT : 'heoLKxbMmlj2rHaSRrzl',
  GROUP_COACH_MANAGER: 'hi8C1zx4pxgEvVutBc80',
  SUPER_ADMIN: 'kYFpmWLwn32fcggeTQ3f',
  CONTENT_EDITOR: 'lTmYTsknr1M2xS5QJ5aY',
  POPULATION_MANAGER: 've5toSsVtpqgKNEiBKNU',
  MANUFACTURER: 'zMTjxL6fQKvcstQWyr0J',
  CLINICIAN: 'owEnZSKpuVSHfA0gf675',
  CLINICIAN_SECONDARY: '5SjtUPiLCM68u1BTxTyB',
  NETWORK_USER: 'GJEHzuKxhZVSQtIEJa8z'
};

