import { Component, OnInit, Input } from '@angular/core';
import { CompanyMarketplace, Product } from "@app/_core/models";

import SwiperCore, { Navigation, SwiperOptions } from 'swiper';
SwiperCore.use([Navigation]);

import { MatDialog } from '@angular/material/dialog';
import { Settings, AppSettings } from 'src/app/app.settings';
import { Router } from '@angular/router';
import { CompanyMarketplaceService } from '@app/_core/services/firestore';

@Component({
  selector: 'app-common-product',
  templateUrl: './common-product.component.html',
  styleUrls: ['./common-product.component.scss']
})
export class CommonProductComponent implements OnInit {

  @Input() product: Product;
  @Input() view:string;

  variantType: string;
  defaultImage: string;
  selectedImage: string;

  public config: SwiperOptions = {};
  public settings: Settings;
  public companyData: CompanyMarketplace;
  
  constructor(public appSettings: AppSettings, public dialog: MatDialog, private router: Router, private companyMarketplaceService: CompanyMarketplaceService) {
    this.settings = this.appSettings.settings;
  }


  ngOnInit() {
    this.companyData = this.companyMarketplaceService.getMarketplaceSettings();

    this.defaultImage = this.product.images?.length ? this.product.images[0] : null;
    this.selectedImage = this.defaultImage;
    
    this.variantType = this.product?.size?.length ? 'size' : this.product?.color?.length ? 'color' : this.product?.sizeColor?.length ? 'sizeColor' : null;
    if (this.variantType) {
      this.product[this.variantType][0].selected = true;

      if (this.product[this.variantType][0].images?.length) this.selectedImage = this.product[this.variantType][0].images[0];
    }
  }

  ngAfterViewInit() {
    this.config = {
      observer: true,
      slidesPerView: 1,
      spaceBetween: 16,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        480: {
          slidesPerView: 1
        },
        740: {
          slidesPerView: 2
        },
        960: {
          slidesPerView: 3
        },
        1280: {
          slidesPerView: 4
        },
        1500: {
          slidesPerView: 5
        }
      }
    }
  }

  public navigate(id: string, name: string) {
    const slug = this.companyData?.slug;
    if (slug) {
      this.router.navigate([slug + '/products', id, name])
    } else {
      this.router.navigate(['products', id, name])
    }
  }

  public onImgError(event: any) {
    event.target.src = this.companyData?.faviconSrc || 'https://via.placeholder.com/1050?text=No Image';
  }

  public getVariantDisplay(item) {
    return this.variantType == 'sizeColor' ? item.sizeName : this.variantType === 'size' ? item.name : '';
  }

  public getVariantCss(item) {
    return this.variantType == 'sizeColor' ? item.colorName.toLowerCase() : item.name.toLowerCase();
  }

  /**
   * Set the selected variant of the product
   * @param type: the variant type, size, color or both
   * @param selection: the item selected
   */
  public selectVariant(selection: any) {
    this.product[this.variantType].forEach(item => {
      if (item.external_id === selection.external_id) {
        item.selected = true;
        this.selectedImage = item?.images?.length ? item.images[0] : this.defaultImage;
      }
      else item.selected = false;
    })
  }
}