import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Settings, AppSettings } from './app.settings';
import { TranslateService } from '@ngx-translate/core';

import { AuthService } from '@app/_core/services/auth/auth.service';
import tinycolor from "tinycolor2";
import { CompanyMarketplaceService } from './_core/services/firestore/company-marketplace/company-marketplace.service';
import { Subject, takeUntil } from 'rxjs';
export interface Color {
  name: string;
  hex: string;
  darkContrast: boolean;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  loading: boolean = false;
  public settings: Settings;
  primaryColor = '#d83621';
  accentColor = '#868e96';
  primaryColorPalette: Color[] = [];
  accentColorPalette: Color[] = [];
  public companyData;
  private _onDestroy = new Subject();

  constructor(public appSettings: AppSettings,
    public router: Router,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object,
    public translate: TranslateService,
    private auth: AuthService,
    private companyMarketplaceService: CompanyMarketplaceService) {
    this.auth.init();

    this.settings = this.appSettings.settings;

    this.companyData = this.companyMarketplaceService.getMarketplaceSettings();

    if (this.companyData?.primaryHex) {
      this.primaryColor = this.companyData?.primaryHex;
    }

    if (this.companyData?.secondaryHex)
      this.accentColor = this.companyData.secondaryHex;
      
    this.savePrimaryColor();
    this.saveAccentColor();

    translate.addLangs(['en', 'de', 'fr', 'ru', 'tr']);
    translate.setDefaultLang('en');
    translate.use('en');
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this._onDestroy.next(true);
    this._onDestroy.complete();
  }

  ngAfterViewInit() {
    this.router.events.subscribe(async event => {
      if (event instanceof NavigationEnd) {
        if (isPlatformBrowser(this.platformId)) {
          window.scrollTo(0, 0);
        }

        const params = this.route.snapshot.queryParams;
        
        // Handle login token and remove param
        if (params?.token) {
          await this.auth.tokenSignIn(params.token);

          this.router.navigate([], {
            queryParams: {
              'token': null
            },
            queryParamsHandling: 'merge'
          })
        }
      }
    })
  }

  savePrimaryColor() {
    this.primaryColorPalette = computeColors(this.primaryColor);
    updateTheme(this.primaryColorPalette, 'primary');
  }

  saveAccentColor() {
    this.accentColorPalette = computeColors(this.accentColor);
    updateTheme(this.accentColorPalette, 'accent');
  }


}

function updateTheme(colors: Color[], theme: string) {
  colors.forEach(color => {
    document.documentElement.style.setProperty(
      `--theme-${theme}-${color.name}`,
      color.hex
    );
    document.documentElement.style.setProperty(
      `--theme-${theme}-contrast-${color.name}`,
      color.darkContrast ? 'rgba(black, 0.87)' : 'white'
    );
  });
}
function computeColors(hex: string): Color[] {
  return [
    getColorObject(tinycolor(hex).lighten(52), '50'),
    getColorObject(tinycolor(hex).lighten(37), '100'),
    getColorObject(tinycolor(hex).lighten(26), '200'),
    getColorObject(tinycolor(hex).lighten(12), '300'),
    getColorObject(tinycolor(hex).lighten(6), '400'),
    getColorObject(tinycolor(hex), '500'),
    getColorObject(tinycolor(hex).darken(6), '600'),
    getColorObject(tinycolor(hex).darken(12), '700'),
    getColorObject(tinycolor(hex).darken(18), '800'),
    getColorObject(tinycolor(hex).darken(24), '900'),
    getColorObject(tinycolor(hex).lighten(50).saturate(30), 'A100'),
    getColorObject(tinycolor(hex).lighten(30).saturate(30), 'A200'),
    getColorObject(tinycolor(hex).lighten(10).saturate(15), 'A400'),
    getColorObject(tinycolor(hex).lighten(5).saturate(5), 'A700')
  ];
}

function getColorObject(value, name): Color {
  const c = tinycolor(value);
  return {
    name: name,
    hex: c.toHexString(),
    darkContrast: c.isLight()
  };
}
