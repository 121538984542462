<h2 mat-dialog-title>Select Related Products</h2>
<div mat-dialog-content>
    <mat-card appearance="outlined" class="p-0">
        <div class="mat-mdc-table admin-table">
            <div class="mat-mdc-header-row">
                <div class="mat-mdc-header-cell">Select</div>
                <div class="mat-mdc-header-cell">Image</div>
                <div class="mat-mdc-header-cell">Name</div>
                <div class="mat-mdc-header-cell">Price</div>
            </div>
            <div *ngFor="let product of products" class="mat-mdc-row">
                <div class="mat-mdc-cell">
                    <mat-checkbox [checked]="isCheckValue(product)" (change)="selectRelatedProduct($event.checked, product)"></mat-checkbox>
                </div>
                <div class="mat-mdc-cell"><img [src]="product.images && product.images[0]" (error)="onImgError($event)"
                        width="50" />
                </div>
                <div class="mat-mdc-cell">
                    {{product?.name}}
                </div>
                <div class="mat-mdc-cell">
                    ${{product.price | number : '1.2-2'}}
                </div>
            </div>
        </div>
    </mat-card>
    <div *ngIf="products.length > 0" fxLayout="row wrap">
        <div fxFlex="100" class="p-1">
            <mat-paginator [length]="totalCount" [pageSize]="count" [pageIndex]="page - 1"
                (page)="onPageChanged($event)" [pageSizeOptions]="[12, 24, 48]" aria-label="Select page">
            </mat-paginator>
        </div>
    </div>
</div>

<div mat-dialog-actions>
    <div fxLayout="row" fxLayoutAlign="center" class="w-100">
        <button mat-raised-button color="warn" (click)="onDismiss()">Cancel</button>
        <button mat-raised-button color="primary" (click)="onConfirm()">Select</button>
    </div>
</div>