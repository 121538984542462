import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppService } from '@app/app.service';
import { Account } from '@app/_core/models';
import { AccountBillingService, AccountService } from '@app/_core/services/firestore';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-common-address',
  templateUrl: './common-address.component.html',
  styleUrls: ['./common-address.component.scss']
})
export class CommonAddressComponent {

  @Input() type: string;
  @Input() checkoutProcess: boolean;
  @Input() isSubmitBtn: boolean = true;
  @Output() validFormSet = new EventEmitter<any>();
  addressForm: UntypedFormGroup;

  countries = [];
  states = [];
  private selectedCountry: string = 'US';

  public currentUser: Account;
  addressData: any;
  public isBillingAddress: boolean = false;
  public deliveryMethods = [];
  constructor(public appService: AppService, public formBuilder: UntypedFormBuilder,
    private accountService: AccountService, private accountBillingService: AccountBillingService, private spinner: NgxSpinnerService, public snackBar: MatSnackBar) { }


  ngOnInit() {
    this.initialiseForm();
    this.deliveryMethods = this.appService.getDeliveryMethods();
    this.isBillingAddress = this.type === 'billingAddress';
    this.accountService.currentAccountInfo
      .subscribe(account => {
        this.currentUser = account;
        if (this.currentUser)
          this.patchFormValue();
      })

    this.states = this.appService.getStates(this.selectedCountry);

    this.addressForm.valueChanges.subscribe(x => {
      this.validFormSet.emit({ valid: this.addressForm.valid, value: this.addressForm.value });

      if (this.selectedCountry !== x.country) {
        this.states = this.appService.getStates(x.country) || [];

        this.selectedCountry = x.country;
        this.addressForm.patchValue({state: ''}, {emitEvent: false})
      }
      
    })
  }

  initialiseForm() {
    this.countries = this.appService.getCountries();
    this.states = this.appService.getStates();

    this.addressForm = this.formBuilder.group({
      'first_name': ['', Validators.required],
      'last_name': ['', Validators.required],
      'company': '',
      'email': ['', Validators.required],
      'phone': ['', Validators.required],
      'country': [this.selectedCountry, Validators.required],
      'city': ['', Validators.required],
      'state': '',
      'zip': ['', Validators.required],
      'address1': ['', Validators.required],
      'address2': ''  
    });
  }

  async patchFormValue() {
    if (this.type) {
      this.addressData = await this.accountBillingService.getData(this.currentUser?._id) || {};
      const { billingAddress = {}, shippingAddress = {} } = this.addressData;
      this.isBillingAddress = this.type === 'billingAddress';
      this.addressForm.patchValue(this.isBillingAddress ? billingAddress : shippingAddress);
    }
  }

  public async onAddressSubmit(values: Object) {
    try {
      if (this.addressForm.valid) {
        this.spinner.show();
        const formData = this.addressForm.value;
        const { _id = '' } = this.addressData;
        const data = this.isBillingAddress ? { billingAddress: formData } : { shippingAddress: formData }
        if (_id)
          await this.accountBillingService.update(_id, data);
        else
          await this.accountBillingService.create(data, this.currentUser?._id);
        this.spinner.hide();
        await this.patchFormValue();
        this.snackBar.open(`${this.isBillingAddress ? 'Billing' : 'Shipping'} address information saved!`, '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
      }
    } catch (err) {
      this.snackBar.open(err?.message, '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    }
  }
}