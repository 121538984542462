/**
 * Service for interacting with accounts in Firestore, extends BaseFirestoreService
 * This is in conjunction with the auth service not replacing it
 *
 * @todo type an account
 * @since 0.0.1
 */

import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { ApiFirestoreService } from '@app/_core/services/api-firestore/api-firestore.service';
import { ProductsCompany } from '@app/_core/models/firestore/products/products-company.model';
import { QueryVars } from '@app/_core/models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CompanyMarketplaceService } from '../company-marketplace/company-marketplace.service';

@Injectable({
    providedIn: 'root'
})
export class ProductsCompanyService extends ApiFirestoreService<ProductsCompany> {
    constructor(
        afs: AngularFirestore,
        private analytics: AngularFireAnalytics,
        private companyMarketplaceService: CompanyMarketplaceService
    ) {
        super('productsCompany', afs);
    }

    async getProductsOfCompany(productId?: string) {
        const companyData = this.companyMarketplaceService.getMarketplaceSettings();
        let data: ProductsCompany[] = [];
        try {
            if (companyData?._id) {
                const condition: QueryVars = { where: [{ field: 'company_id', comparator: '==', value: companyData._id }] };
                if (productId) {
                    condition.where.push({ field: 'product_id', comparator: '==', value: productId })
                }
                data = await this.getQueryData(condition);
            }
            return data;
        } catch (error) {
            throw error
        }
    };

    async getCustomizedProducts(data: string[]) {
        const companyData = this.companyMarketplaceService.getMarketplaceSettings();
        try {
            let productsCompany: ProductsCompany[] = [];
            if (companyData?._id) {
                /* Used Iteration For Firebase Array contains filter supports only 10 data */
                for (let i = 0; i < data.length; i += 10) {
                    const condition: QueryVars = { where: [{ field: 'company_id', comparator: '==', value: companyData._id }] };
                    condition.where.push({ field: 'product_id', comparator: 'in', value: data.slice(i, i + 10) });
                    const newProductsCompany = await this.getQueryData(condition) || [];
                    productsCompany = [...productsCompany, ...newProductsCompany];
                }
            };

            return productsCompany;
        } catch (error) {
            throw error;
        }
    }
}
