/**
 * Service for interacting with accounts in Firestore, extends BaseFirestoreService
 * This is in conjunction with the auth service not replacing it
 *
 * @todo type an account
 * @since 0.0.1
 */

import { Injectable, Inject } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AngularFirestore } from '@angular/fire/compat/firestore';

import { Subject } from 'rxjs';

import { ApiFirestoreService } from '@app/_core/services/api-firestore/api-firestore.service';
import { CompanyMarketplace } from '@app/_core/models/firestore/company-marketplace.model';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyMarketplaceService extends ApiFirestoreService<CompanyMarketplace> {
  ngUnsubscribe: Subject<any>;
  private companies: CompanyMarketplace[];
  private slug: string;

  constructor(afs: AngularFirestore,
    private location: Location,
    private router: Router, 
    private snackBar: MatSnackBar,
    private titleService: Title,
    @Inject(DOCUMENT) private document: HTMLDocument) {
    super('companyMarketplaces', afs);
  }

  init() {
    let url = this.location.path();

    return new Promise<void>(async (resolve, reject) => {
      if (!url) url = environment.defaultSlug;

      if (url) {
        this.slug = url.split('/')[1].split('?')[0].split('%3F')[0];
        this.companies = await this.getByField('slug', this.slug) || [];

        this.reconfigureRoutes(url);
      } else {
        this.reconfigureRoutes();
      }
      resolve();
    })
  }

  private reconfigureRoutes(url?: string) {
    setTimeout(async () => {
      let routes = this.router.config;

      if (this.companies?.length && this.companies[0].enabled) {
        if (this.companies[0].faviconSrc) this.document.getElementById('ampmkplFavicon').setAttribute('href', this.companies[0].faviconSrc);
        
        routes[0].path = this.slug;
        routes[0].data = this.companies[0];

        if (this.companies[0].title) this.titleService.setTitle(this.companies[0].title);
      } else {
        // If we don't find a company then show the amptify module
        routes.shift();
        routes[0].path = '';
      }
      this.router.resetConfig(routes);

      if (url) {
        const queryParams: any = {};
        const [path, queryString] = url.split('?');
        if (queryString) {
          const searchParams = new URLSearchParams(queryString);
          searchParams.forEach((value, key) => {
            queryParams[key] = value;
          });
        }
        
        // Use the extracted path and queryParams for navigation
        this.router.navigate([path || '/'], { queryParams });
      } else {
        this.router.navigate(['/']);
      }
    })
  }

  private handleParams() {

  }

  getMarketplaceSettings() {
    return this.companies?.find(data => data.slug === this.slug);
  }
}
