import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { DiscountClubStatistics } from '@app/_core/models/firestore/discounts-networks';

import { ApiFirestoreService } from '@app/_core/services/api-firestore/api-firestore.service';

@Injectable({
    providedIn: 'root'
})
export class DiscountClubStatisticsService extends ApiFirestoreService<DiscountClubStatistics> {
    constructor(public afs: AngularFirestore) {
        super('discountClubStatistics', afs);
    }
}