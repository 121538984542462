import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { SHIPPING_CARRIERS } from '@app/_core/constants/app-constants';

@Component({
  selector: 'app-edit-tracking',
  templateUrl: './edit-tracking.component.html',
  styleUrls: ['./edit-tracking.component.scss']
})
export class EditTrackingComponent {
  trackingForm: FormGroup;

  carriers: { name: string; value: string }[] = SHIPPING_CARRIERS;

  constructor(private dialogRef: MatDialogRef<EditTrackingComponent>,
              private formBuilder: FormBuilder
  ) {
    this.trackingForm = this.formBuilder.group({
      trackingNumber: ['', Validators.required],
      shippingCarrier: ['', Validators.required]
    });
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    const values = this.trackingForm.value;
    this.dialogRef.close(values);
  }

}
