import { from, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';

import firebase from 'firebase/compat/app';
import {
  CollectionReference,
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  DocumentSnapshot,
  Query, QueryFn
} from '@angular/fire/compat/firestore';

import { take } from 'rxjs/operators';

import { IApiFirestoreService } from './IApiFirestoreService';
import { QueryVars } from '@app/_core/models';
import { ErrorHandlerService } from "@app/_core/services/error-handler.service";

export abstract class ApiFirestoreService<T> implements IApiFirestoreService<T>  {

  protected collection: AngularFirestoreCollection<T>;
  protected _path: string;

  constructor(private path: string,
    protected afs: AngularFirestore) {
    this._path = path;
    this.collection = this.afs.collection(path);
  }

  get(identifier: string): Promise<any> {
    //this.logger.logVerbose(`[BaseService] getPromise: ${identifier}`);

    return this.collection
      .doc(identifier)
      .ref.get();
  }

  /**
   * Get data and return it handled
   *
   * @since 0.0.1
   */
  getData(identifier: string): Promise<T> {
    return new Promise((resolve, reject) => {
      this.get(identifier).then(doc => {
        if (doc.exists)
          resolve(doc.data());
        else
          resolve(null);
      }, err => {
        ErrorHandlerService.catch("[BaseFirestoreService] getData collection: " + this._path, err);
        reject(err);
      });
    });
  }

  getAll(): Promise<any> {
    return this.collection.ref.get();
  }

  /**
   * Get all documents in collection as their data
   *
   * @since 0.0.0
   */
  getAllData(): Promise<T[]> {
    return new Promise<any>((resolve, reject) => {
      this.collection.ref.get().then(querySnapshot => {
        let allData;

        if (querySnapshot && querySnapshot.size) {
          allData = [];
          querySnapshot.docs.forEach(doc => {
            allData.push(doc.data());
          });
        }

        resolve(allData);
      }, err => {
        ErrorHandlerService.catch('[BaseFirestoreService] getAllData collection: ' + this._path, err);
        reject(err);
      })
    })
  }

  /**
   * Get data by account ID
   *
   * @since 0.0.0
   */
  getByAccount(account_id: string, order?: string, dir?: string): Promise<T[]> {
    const query: QueryVars = { where: [{ field: 'account_id', comparator: '==', value: account_id }] };

    if (order) query.order = order;
    if (dir) query.dir = dir;

    return this.getQueryData(query);
  }

  /**
   * Get data by some field
   *
   * @since 0.0.0
   */
  async getByField(field: string, value: string, order?: string, dir?: string): Promise<T[]> {
    const query: QueryVars = { where: [{ field: field, comparator: '==', value: value }] };

    if (order) query.order = order;
    if (dir) query.dir = dir;

    return this.getQueryData(query);
  }


  /**
   * Get a group of documents from an array of ids
   *
   * @since 0.0.1
   */
  getGroup(ids: string[]): Promise<T[]> {
    let promises = [];

    ids.forEach(id => {
      promises.push(this.getData(id));
    });

    return Promise.all(promises).then(res => {
      let filtered = res.filter((el) => {
        return el != null;
      });
      return filtered;
    }).catch((err) => {
      ErrorHandlerService.catch("[BaseFirestoreService] getGroup collection: " + this._path, err);
      return err;
    });
  }

  watch(identifier: string): Observable<T> {
    //this.logger.logVerbose(`[BaseService] watch: ${identifier}`);

    return this.collection
      .doc<T>(identifier)
      .valueChanges();
  }

  /**
   * Watch for snapshot changes but also return the fromCache value to tell if the data is live or from persistence
   *
   * @since 0.0.0
   */
  watchLive(identifier: string): Observable<{fromCache: boolean, data: T}> {
    return this.collection
      .doc<T>(identifier)
      .snapshotChanges().pipe(
        map(changes => {
          return {fromCache: changes.payload.metadata.fromCache, data: changes?.payload?.data() as T};
        })
      );
  }

  watchQuery(queryVars: QueryVars): Observable<any> {
    //this.logger.logVerbose(`[BaseService] watchWhere: ${where}, ${identifier}, ${order}, ${dir}`);

    return this.afs.collection(this._path, ref => {
      return this.buildQuery(ref, queryVars);
    }).valueChanges();
  }

  watchAll(): Observable<T[]> {
    //this.logger.logVerbose(`[BaseService] watchAll: ${this._path}`);

    return this.collection.valueChanges();
  }

  /**
   * Get unhandled data through a query
   * 
   * @since 0.0.0
   */
  getQuery(queryVars: QueryVars) {
    return this.afs.collection(this._path, ref => {
      return this.buildQuery(ref, queryVars);
    }).get();
  }

  /**
   * Get handled data as an array through a query
   *
   * @since 0.1.2
   */
  getQueryData(queryVars: QueryVars): Promise<T[]> {
    return new Promise<T[]>((resolve, reject) => {
      this.getQuery(queryVars).pipe(first()).toPromise().then(snapshot => {
        if (snapshot?.size) {
          let allItems = [];

          snapshot.docs.forEach(doc => {
            let docData = doc.data();
            allItems.push(docData);
          })
          resolve(allItems);
        } else {
          resolve(null);
        }
      }, err => {
        console.log("err", err)
        reject(err);
      })
    });
  }

  /**
   * For queries with the 'in' comparator this splits up the query into chunks of ten and combines results
   * 
   * @since 0.8.19
   */
  getInQueryData(field: string, values: any[], extraVars?: QueryVars) {
    return new Promise<T[]>(async (resolve, reject) => {
      const len = values.length;
      const limit = 10;

      let result = [];
      for (var i = 0; i < len; i += limit) {
        let start = i;
        let end = i + limit;
        if (end >= len) end = len;

        const splitVals = values.slice(start, end);

        let query: QueryVars = {};

        if (extraVars) query = extraVars;
        if (!query?.where) query.where = [];

        query.where.push({ field: field, comparator: 'in', value: splitVals });

        const subRes = await this.getQueryData(query);
        result = [
          ...result,
          ...(subRes ? subRes : [])
        ]
      }

      resolve(result);
    })
  }

  /**
   * Special get query to get a field by string that starts with the given string value
   * 
   * @since 0.8.1
   */
  getStartsWith(field: string, value: string, extras?: { field: string, comparator: string, value: string | string[] | boolean }[]) {
    const end = value.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));

    let query: QueryVars = {
      where: [
        { field: field, comparator: '>=', value: value },
        { field: field, comparator: '<', value: end }
      ]
    };

    if (extras?.length) {
      extras.forEach(extra => {
        if (query.where?.length)
          query.where.push(extra);
      });
    }

    return this.getQuery(query);
  }

  /**
   * Watch a collection that is inside a document
   *
   * @since 2.1.0
   */
  watchDocCollection(identifier: string, collection: string, queryVars: QueryVars) {
    return this.collection.doc<T>(identifier).collection(collection, ref => {
      return this.buildQuery(ref, queryVars);
    }).valueChanges();
  }

  /**
   * Helper to build collection query
   *
   * @since 2.1.0
   */
  private buildQuery(ref: CollectionReference, queryVars: QueryVars) {
    let query: Query = ref;

    if (queryVars.where && queryVars.where.length) {
      queryVars.where.forEach(clause => {
        query = query.where(clause.field, clause.comparator, clause.value);
      });
    }
    if (queryVars.orderBy?.length) {
      queryVars.orderBy.forEach(clause => {
        query = query.orderBy(clause.order, clause.dir);
      });
    }
    if (queryVars.order && queryVars.dir) {
      query = query.orderBy(queryVars.order, queryVars.dir);
    }
    if (queryVars.limit) {
      query = query.limit(queryVars.limit);
    }
    if (queryVars.startAfter) {
      query = query.startAfter(queryVars.startAfter);
    }

    return query;
  }

  /***************************************
   *       PAGINATION FUNCTIONS
   **************************************/

  /**
   * Get paginated results and last item ref to run pagination again
   *
   * @since 0.8.4
   */
  async getPagination(queryVars: QueryVars) {
    if (!queryVars.limit)
      return Promise.reject('Must set a results limit for pagination.');

    const snapshot = await this.getQuery(queryVars).pipe(first()).toPromise();

    if (snapshot?.size) {
      let allItems = [];

      snapshot.docs.forEach(doc => {
        let docData = doc.data();
        allItems.push(docData);
      });

      return { items: allItems, lastRef: snapshot.docs[snapshot.size - 1] };
    } else {
      return null;
    }
  }

  /**
   * Start a pagination query, after this you must call page to get next data
   *  returns valueChanges from watchQuery
   *
   * @since 0.0.0
   */
  paginate(limit: number, queryVars: QueryVars = {}): Observable<any> {
    //this.logger.logVerbose(`[BaseService] paginate: ${this._path}`);
    queryVars.limit = limit;

    return this.watchQuery(queryVars);
  }

  /**
   * Get the next page of data, last Item is the ID of the last item returned
   *
   * @since 0.0.0
   */
  page(limit: number, lastItem: string, queryVars: QueryVars = {}): Promise<Observable<any>> {
    //this.logger.logVerbose(`[BaseService] page: ${this._path}`);
    return this.collection.doc(lastItem).ref.get().then(doc => {
      return this.afs.collection(this._path, ref => {
        let query: Query = this.buildQuery(ref, queryVars);

        query = query.limit(limit).startAfter(doc);

        return query;
      }).valueChanges();
    })
  }

  /** 
   * I have the last document reference so use it instead of a promise to get next paged data
   *
   * @since 0.1.0
   */
  pageRef(limit: number, lastItem: DocumentSnapshot<T>, queryVars: QueryVars = {}): Observable<any> {
    return this.afs.collection(this._path, ref => {
      let query: Query = this.buildQuery(ref, queryVars);

      query.limit(limit).startAfter(lastItem);

      return query;
    }).valueChanges();
  }

  list(): Observable<T[]> {
    //this.logger.logVerbose(`[BaseService] list`);

    return this.collection
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(a => {
            const data = a.payload.doc.data() as T;
            const id = a.payload.doc.id;
            //data.id = a.payload.doc.id;
            return { _id: id, ...data };
          });
        })
      );
  }

  /**
   * Add to a collection within a doc
   *
   * @since 0.0.1
   */
  addToDocCollection(identifier: string, collection: string, item: any) {

    return new Promise<any>((resolve, reject) => {
      let id = this.afs.createId();

      item.dateCreated = firebase.firestore.FieldValue.serverTimestamp();
      item._id = id;

      this.collection.doc(identifier).collection(collection).doc(id).set(item).then(ref => {
        resolve(item);
      }, err => {
        ErrorHandlerService.catch("[BaseFirestoreService] addToDocCollection collection: " + this._path, err);
        reject(err);
      })
    });
  }

  /**
   * Base create that is used for every collection.
   * Further defined in collection specific services
   *  @return created data including _id and dateCreated
   *
   * @since 2.0.0
   */
  create(data: any, id: string = null): Promise<any> {
    //this.logger.logVerbose(`[BaseService] creating item`, data);

    const promise = new Promise<any>((resolve, reject) => {
      if (!id) id = this.afs.createId();

      data.dateCreated = firebase.firestore.FieldValue.serverTimestamp();
      data._id = id;

      const today = new Date();
      data.isoDate = today.toISOString().substr(0, 10);

      this.collection.doc(id).set(data).then(ref => {
        resolve(data);
      }, err => {
        ErrorHandlerService.catch("[BaseFirestoreService] create collection: " + this._path, err);
        //this.logger.logError(err);
        reject(err);
      });
    });
    return promise;
  }

  /**
   * Update a firestore document
   *  @param otherItems - this is only used in overload functions if needed
   *
   * @since 0.0.0
   */
  update(id: string, item: any): Promise<T> {
    //this.logger.logVerbose(`[BaseService] updating item ${item.id}`);

    const promise = new Promise<T>((resolve, reject) => {
      const today = new Date();
      item.isoDateUpdated = today.toISOString().substr(0, 10);
      item.dateUpdated = firebase.firestore.FieldValue.serverTimestamp();

      const docRef = this.collection
        .doc<T>(id)
        .update(item)
        .then((data) => {
          resolve({
            ...(item as any)
          });
        }).catch(err => { ErrorHandlerService.catch("[BaseFirestoreService] update collection: " + this._path, err); reject(err) });
    });
    return promise;
  }

  delete(id: string): Promise<any> {
    //this.logger.logVerbose(`[BaseService] deleting item ${id}`);

    const docRef = this.collection.doc<T>(id);
    return docRef.delete();
  }

  /**
   * Sort of a hack to do search, can only search from the beginning of item
   *
   * @since 2.0.40
   */
  searchStartAt(searchQuery: string, queryVars: any) {
    //this.logger.logVerbose(`[BaseService] startAt search ${searchQuery}`);

    return this.afs.collection(this._path, ref => {
      let query: Query;
      const end = searchQuery.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));

      query = ref.orderBy(queryVars.order)
        .startAt(searchQuery)
        .endAt(searchQuery + "\uf8ff");

      if (queryVars.where && queryVars.identifier)
        query = query.where(queryVars.where, '==', queryVars.identifier);
      if (queryVars.limit)
        query.limit(queryVars.limit);

      return query;
    }).valueChanges();
  }

  /**
   * Use a transaction to add to an array
   *
   * @since 0.0.1
   */
  addToArray(id: string, field: string, item: any) {
    return new Promise<any>((resolve, reject) => {
      this.update(id, { [field]: firebase.firestore.FieldValue.arrayUnion(item) }).then(res => {
        resolve(res);
      }, err => {
        ErrorHandlerService.catch("[BaseFirestoreService] addToArray collection: " + this._path, err);
        reject(err);
      })
    });
  }

  /**
   * Use array remove to remove an item from an array
   *
   * @since 0.0.1
   */
  removeFromArray(id: string, field: string, item: any) {
    return new Promise<any>((resolve, reject) => {
      this.update(id, { [field]: firebase.firestore.FieldValue.arrayRemove(item) }).then(res => {
        resolve(res);
      }, err => {
        ErrorHandlerService.catch("[BaseFirestoreService] removeFromArray collection: " + this._path, err);
        reject(err);
      })
    })
  }

  /**
   * Use a transaction to add or remove from an array
   *
   * @since 0.1.3
   */
  addRemoveFromArray(id: string, field: string, item: any) {
    const docRef = this.afs.collection(this._path).doc(id);

    // Use a transaction in case multiple writes are happening at the same time
    return this.afs.firestore.runTransaction(t => {
      return t.get(docRef.ref)
        .then((docSnapshot: DocumentSnapshot<T>) => {
          let data = docSnapshot.data();

          if (data[field].includes(item)) {
            t.update(docRef.ref, { [field]: firebase.firestore.FieldValue.arrayRemove(item) });
          } else {
            t.update(docRef.ref, { [field]: firebase.firestore.FieldValue.arrayUnion(item) });
          }
        }, err => {
          ErrorHandlerService.catch('[BaseFirestoreService] addRemoveFromArray collection: ' + this._path, err);
        })
    }).catch(err => {
      ErrorHandlerService.catch('[BaseFirestoreService] addRemoveFromArray collection: ' + this._path, err);
    })
  }

  /**
   * Get handled data as an array through a query
   *
   * @since 0.1.2
   */
  getReferenceByQuery(queryVars: QueryVars): Promise<T[]> {
    return new Promise<T[]>((resolve, reject) => {
      this.getQuery(queryVars).pipe(first()).toPromise().then(snapshot => {
        if (snapshot?.size) {
          let allItems = [];

          snapshot.docs.forEach(doc => {
            allItems.push(doc);
          })
          resolve(allItems);
        } else {
          resolve(null);
        }
      }, err => {
        console.log("err", err)
        reject(err);
      })
    });
  }

  /**
   * delete documents with query
   * 
   * @since 0.6.5
   */
  deleteFromQuery(queryVars: QueryVars) {
    return new Promise<string[]>(async (resolve, reject) => {
      try {
        const batch = this.collection.ref.firestore.batch();
        const list = await this.getReferenceByQuery(queryVars);

        const ids = [];
        if (!list) resolve(ids);
        list.forEach((doc: any) => {
          ids.push(doc.id);
          batch.delete(doc.ref)
        });

        await batch.commit();
        resolve(ids);
      } catch (error) {
        reject(error);
      }
    })
  }

  /**
   * get firestore timestamp from utc time string
   * 
   * @since 0.8.6
   */
  getTimestampFromUTCString(utcDate: string) {
    utcDate = utcDate.trim();
    utcDate += " GMT";

    const gmt = Date.parse(utcDate);
    return firebase.firestore.Timestamp.fromDate(new Date(gmt));
  }

  /**
     * manage arrayUnion
     * 
     * @since 0.8.7
     */
  arrayUnion(val: string) {
    return firebase.firestore.FieldValue.arrayUnion(val);
  }

  /**
   * get firestore timestamp
   * 
   * @since 0.8.9
   */
  getFirestoreTimestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  /**
   * select list with id set
   * 
   * @since 0.8.12
   */
  selectSetWithIds(ids: string[]) {
    let query: QueryVars = { where: [{ field: '_id', comparator: 'in', value: ids }] };
    return this.getQueryData(query);;
  }

  /**
   * create a new ID for a new document
   * 
   * @since 0.8.12
   */
  generateID() {
    return this.afs.createId();
  }

  /**
   * get delete operation
   * 
   * @since 0.8.15
   */
   getOperationForDeletion() {
    return firebase.firestore.FieldValue.delete();
  }
}