import { Injectable } from '@angular/core';

import firebase from 'firebase/compat/app';
import { AngularFirestore } from '@angular/fire/compat/firestore';

//import { QueryVars } from '@app/shared/models';
import { SelfPayDiscount } from '@app/_core/models/firestore/discounts-networks';

import { ApiFirestoreService } from '@app/_core/services/api-firestore/api-firestore.service';

@Injectable({
  providedIn: 'root'
})
export class SelfPayDiscountService extends ApiFirestoreService<SelfPayDiscount> {

  constructor(afs: AngularFirestore) {
    super('selfPayDiscounts', afs);
  }
}
