<h1 mat-dialog-title>Select Items For Return</h1>

<div mat-dialog-content>
    <div fxLayout="row wrap">
        <div fxFlex="100" fxFlex.gt-sm="100" style="width: 350px;">
            <mat-form-field class="w-100">
                <mat-label>Toppings</mat-label>
                <mat-select [formControl]="items" multiple>
                    <mat-select-trigger>
                        {{items.value?.[0]?.name || ''}}
                        <span *ngIf="(items.value?.length || 0) > 1" class="example-additional-selection">
                            (+{{(items.value?.length || 0) - 1}} {{items.value?.length === 2 ? 'other' : 'others'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let item of itemsList" [value]="item">
                        {{item?.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="items.errors?.required">Items is required</mat-error>
            </mat-form-field>
        </div>
    </div>
</div>

<div mat-dialog-actions>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
        <button mat-raised-button color="warn" (click)="onDismiss()">Cancel</button>
        <button mat-raised-button color="primary" (click)="onConfirm()">Confirm</button>
    </div>
</div>