<form [formGroup]="addressForm" (ngSubmit)="onAddressSubmit(addressForm.value)" *ngIf="!checkoutProcess">
  <div fxLayout="row wrap">
    <div fxFlex="100" fxFlex.gt-sm="50" class="px-1">
      <mat-form-field class="w-100">
        <input matInput placeholder="First name" formControlName="first_name" required>
        <mat-error *ngIf="addressForm.controls.first_name.errors?.required">First name is required</mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="50" class="px-1">
      <mat-form-field class="w-100">
        <input matInput placeholder="Last name" formControlName="last_name" required>
        <mat-error *ngIf="addressForm.controls.last_name.errors?.required">Last name is required</mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="50" class="px-1">
      <mat-form-field class="w-100">
        <input matInput placeholder="Email" formControlName="email" required>
        <mat-error *ngIf="addressForm.controls.email.errors?.required">Email is required</mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="50" class="px-1">
      <mat-form-field class="w-100">
        <input matInput placeholder="Phone" formControlName="phone" required>
        <mat-error *ngIf="addressForm.controls.phone.errors?.required">Phone is required</mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="100" class="px-1">
      <mat-form-field class="w-100">
        <input matInput placeholder="Company" formControlName="company">
      </mat-form-field>
    </div>
    <div fxFlex="100" class="px-1">
      <mat-form-field class="w-100">
        <input matInput placeholder="Address1 (street)" formControlName="address1" required>
        <mat-error *ngIf="addressForm.controls.address1.errors?.required">Address1 is required</mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="100" class="px-1">
      <mat-form-field class="w-100">
        <input matInput placeholder="Address2 (apartment, suite, unit etc.)" formControlName="address2">
      </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="25" class="px-1">
      <mat-form-field class="w-100">
        <mat-select placeholder="Country" formControlName="country" required>
          <mat-option *ngFor="let country of countries" [value]="country.code">
            {{country.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="addressForm.controls.country.errors?.required">Country is required</mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="25" class="px-1">
      <mat-form-field class="w-100">
        <input matInput placeholder="City" formControlName="city" required>
        <mat-error *ngIf="addressForm.controls.city.errors?.required">City is required</mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="25" class="px-1">
      <mat-form-field class="w-100">
        <input matInput placeholder="State/Province" formControlName="state">
        <mat-select placeholder="State/Province" formControlName="state">
          <mat-option *ngFor="let state of states" [value]="state.code">
            {{state.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="25" class="px-1">
      <mat-form-field class="w-100">
        <input matInput placeholder="Zip/Postal Code" formControlName="zip" required>
        <mat-error *ngIf="addressForm.controls.zip.errors?.required">Zip/Postal Code is required</mat-error>
      </mat-form-field>
    </div>
    <div *ngIf="!isBillingAddress && !isSubmitBtn" fxFlex="100">
      <mat-radio-group fxLayout="column" fxLayoutAlign="center" required name="methods">
        <mat-radio-button *ngFor="let method of deliveryMethods" [checked]="method.default" color="primary" [value]="method" class="mt-1">
          <b>{{method.name}}</b> {{method.desc}}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div *ngIf="isSubmitBtn;else content" fxFlex="100" class="text-center mt-2">
      <button mat-raised-button color="primary">Save</button>
    </div>
    <ng-template #content>
      <div fxFlex="100" class="text-center mt-2">
        <button *ngIf="!isBillingAddress" mat-mini-fab matStepperPrevious color="primary" matTooltip="Back"
          matTooltipPosition="before" class="mr-1">
          <mat-icon>navigate_before</mat-icon>
        </button>
        <button mat-mini-fab matStepperNext color="primary" matTooltip="Next" matTooltipPosition="after">
          <mat-icon>navigate_next</mat-icon>
        </button>
      </div>
    </ng-template>
  </div>
</form>

<form [formGroup]="addressForm" *ngIf="checkoutProcess">
  <div fxLayout="row wrap">
    <div fxFlex="100" fxFlex.gt-sm="50" class="px-1">
      <mat-label class="label-cls">First Name</mat-label>
      <mat-form-field class="w-100" appearance="outline">
        <input matInput formControlName="first_name" required>
        <mat-error *ngIf="addressForm.controls.first_name.errors?.required">First name is required</mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="50" class="px-1">
      <mat-label class="label-cls">Last Name</mat-label>
      <mat-form-field class="w-100" appearance="outline">
        <input matInput formControlName="last_name" required>
        <mat-error *ngIf="addressForm.controls.last_name.errors?.required">Last name is required</mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="50" class="px-1">
      <mat-label class="label-cls">Zip</mat-label>
      <mat-form-field class="w-100" appearance="outline">
        <input matInput formControlName="zip" required>
        <mat-error *ngIf="addressForm.controls.zip.errors?.required">Zip/Postal Code is required</mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="50" class="px-1">
      <mat-label class="label-cls">State</mat-label>
      <mat-form-field class="w-100" appearance="outline">
        <mat-select formControlName="state">
          <mat-option *ngFor="let state of states" [value]="state.code">
            {{state.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="50" class="px-1">
      <mat-label class="label-cls">City</mat-label>
      <mat-form-field class="w-100" appearance="outline">
        <input matInput formControlName="city" required>
        <mat-error *ngIf="addressForm.controls.city.errors?.required">City is required</mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="50" class="px-1">
      <mat-label class="label-cls">Street Address</mat-label>
      <mat-form-field class="w-100" appearance="outline">
        <input matInput formControlName="address1" required>
        <mat-error *ngIf="addressForm.controls.address1.errors?.required">Address1 is required</mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="50" class="px-1">
      <mat-label class="label-cls">Additional address information</mat-label>
      <mat-form-field class="w-100" appearance="outline">
        <input matInput formControlName="address2">
      </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="50" class="px-1">
      <mat-label class="label-cls">Country</mat-label>
      <mat-form-field class="w-100" appearance="outline">
        <mat-select formControlName="country" required>
          <mat-option *ngFor="let country of countries" [value]="country.code">
            {{country.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="addressForm.controls.country.errors?.required">Country is required</mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="50" class="px-1">
      <mat-label class="label-cls">Email address</mat-label>
      <mat-form-field class="w-100" appearance="outline">
        <input matInput formControlName="email" required>
        <mat-error *ngIf="addressForm.controls.email.errors?.required">Email is required</mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="50" class="px-1">
      <mat-label class="label-cls">Phone</mat-label>
      <mat-form-field class="w-100" appearance="outline">
        <input matInput formControlName="phone" required>
        <mat-error *ngIf="addressForm.controls.phone.errors?.required">Phone is required</mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="100" class="px-1">
      <mat-label class="label-cls">Company</mat-label>
      <mat-form-field class="w-100" appearance="outline">
        <input matInput formControlName="company">
      </mat-form-field>
    </div>
    <!-- <div *ngIf="!isBillingAddress && !isSubmitBtn" fxFlex="100">
      <mat-radio-group fxLayout="column" fxLayoutAlign="center" required name="methods">
        <mat-radio-button *ngFor="let method of deliveryMethods" [checked]="method.default" color="primary" [value]="method" class="mt-1">
          <b>{{method.name}}</b> {{method.desc}}
        </mat-radio-button>
      </mat-radio-group>
    </div> -->

    <div *ngIf="isSubmitBtn;else content" fxFlex="100" class="text-center mt-2">
      <button mat-raised-button color="primary">Save</button>
    </div>
    <!-- <ng-template #content>
      <div fxFlex="100" class="text-center mt-2">
        <button *ngIf="!isBillingAddress" mat-mini-fab matStepperPrevious color="primary" matTooltip="Back"
          matTooltipPosition="before" class="mr-1">
          <mat-icon>navigate_before</mat-icon>
        </button>
        <button mat-mini-fab matStepperNext color="primary" matTooltip="Next" matTooltipPosition="after">
          <mat-icon>navigate_next</mat-icon>
        </button>
      </div>
    </ng-template> -->
  </div>
</form>