import { Component, Input } from '@angular/core';

import { getOrderLabelByValue } from '@app/_shared/helpers/utils';
import { ORDER_STATUS } from '@app/_core/constants/app-constants';

import { CompanyMarketplace, MarketplaceOrder, Fulfillment } from '@app/_core/models/firestore';
import { MatDialog } from '@angular/material/dialog';
import { EditTrackingComponent } from '@app/_shared/dialogs';
import { MarketplaceOrderService } from '@app/_core/services/firestore';

@Component({
  selector: 'app-fulfillment',
  templateUrl: './fulfillment.component.html',
  styleUrls: ['./fulfillment.component.scss']
})
export class FulfillmentComponent {
  @Input() companyData: CompanyMarketplace;
  @Input() order: MarketplaceOrder;

  @Input() admin: boolean;

  constructor(private dialog: MatDialog,
              private orderService: MarketplaceOrderService
  ) {

  }

  public onImgError(event: any) {
    event.target.src = this.companyData?.faviconSrc || 'https://via.placeholder.com/1050?text=No Image';
  }

  public getOrderStatus(status?: string) {
    return getOrderLabelByValue(status || this.order.status);
  }

  /**
   * Lookup the product name from the order items
   * 
   * @since 0.0.0
   */
  public getProductData(product_id: string, field: string) {
    const product = this.order?.cartItems?.find(x => x._id === product_id);

    if (product)
      return product[field];
  }

  public getProductLinePrice(product_id: string) {
    const product = this.order?.cartItems?.find(x => x._id === product_id);

    if (product) {
      return product.price * product.quantity;
    }
  }

  public getProductImage(product_id) {
    const product = this.order?.cartItems?.find(x => x._id === product_id);

    return product?.images?.length ? product.images[0] : this.companyData?.faviconSrc || 'https://via.placeholder.com/1050?text=No Image';
  }

  public canEditTracking(status: string) {
    return this.admin && [
      'awaiting-fulfillment',
      'awaiting-shipping',
      'awaiting-pickup',
      'partially-shipped'].includes(status);
  }

  public openEditTracking(fulfillment: Fulfillment, index: number) {
    const dialogRef = this.dialog.open(EditTrackingComponent, {
      data: {
        fulfillment: fulfillment
      }
    });

    dialogRef.afterClosed().subscribe(value => {
      if (value?.trackingNumber) {
        this.order.fulfillments[index].trackingNumber = value.trackingNumber;
        this.order.fulfillments[index].shippingCarrier = value.shippingCarrier;

        // Should update the order service here or in the parent
        this.orderService.update(this.order._id, {fulfillments: this.order.fulfillments});
      }
    })
  }
}
