import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  public observer$ = {};

  constructor() { }

  public saveData(key: string, value: string) {
    this.updateSubj(key, value);
    localStorage.setItem(key, value);
  }

  public getData(key: string) {
    const value = localStorage.getItem(key);
    this.updateSubj(key, value);
    return value;
  }
  public removeData(key: string) {
    localStorage.removeItem(key);
    this.updateSubj(key, null);
  }

  public clearData() {
    localStorage.clear();
    for (let key in this.observer$) {
      this.observer$[key].next(null);
      this.observer$[key].complete();
    }
  }

  /**
   * Make local storage item subscribeable
   */
  private updateSubj(key: string, value: string, target: string = 'cart[') {
    if (key.includes(target)) {
      if (this.observer$[key]) this.observer$[key].next(value);
      else this.observer$[key] = new BehaviorSubject<string>(value);
    }
  }
}
