<div *ngIf="products" class="swiper-container" [swiper]="config">
    <div class="swiper-wrapper h-100">
        <div *ngFor="let product of products" class="swiper-slide">
            <mat-card appearance="outlined" class="product-item">
                <app-common-product [product]="product"></app-common-product>
                <div class="icons product-bottom-control">
                    <app-controls [product]="product" (onOpenProductDialog)="openProductDialog(product)"></app-controls>
                </div>
            </mat-card>
        </div>
    </div>
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
</div>