import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientJsonpModule, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';

import { environment } from '@env/environment';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,

    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,

    HttpClientJsonpModule,
    HttpClientModule
  ]
})
export class CoreModule { }