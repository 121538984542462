<h3>Summary & Confirmation</h3>
<div class="summary">
  
  <div class="mat-mdc-cell text-left">
    <div fxLayout="column" fxLayoutAlign="center start" class="grand-total">
      <span class=" fw-600 ">Subtotal</span>
      <span class="text-muted fw-600">Shipping</span>
      <span class="text-muted fw-600">Tax</span>
      <span *ngIf="cartList?.discount" class="text-muted fw-600 ">Discount</span>
    </div>
  </div>
  <div class="mat-mdc-cell text-right">
    <div fxLayout="column" fxLayoutAlign="center end" class="grand-total">
      <span class="fw-600 ">{{ subtotal / 100 | currency }}</span>
      <span class="text-muted fw-600">{{ shippingCost / 100 | currency }}</span>
      <span class="text-muted fw-600">{{ salesTax / 100 | currency }}</span>
      <span *ngIf="cartList?.discount" class="text-muted fw-600 ">0%</span>
    </div>
  </div>
</div>

<ng-container *ngIf="showPromoCode">
  <h3>Add a promotional code</h3>
  <div class="promo-code-wrap">
    <mat-form-field appearance="outline">
      <input matInput placeholder="Enter Coupon Code">
    </mat-form-field>
    <!-- <input class="promo-code-input"/> -->
    <button mat-raised-button class="apply-btn">APPLY</button>
  </div>
</ng-container>

<div class="summary">
  <div class="mat-mdc-cell text-left">
    <div fxLayout="column" fxLayoutAlign="center start" class="grand-total">
      <span class="new-price text-16">Total</span>
    </div>
  </div>
  <div class="mat-mdc-cell text-right">
    <div fxLayout="column" fxLayoutAlign="center end" class="grand-total">
      <span class="new-price text-16">{{ (grandTotal - (grandTotal*0.0)) / 100 | currency }}</span>
    </div>
  </div>
</div>