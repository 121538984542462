
<div class="payment-method-wrap">
  <div *ngIf="submittingPayment" class="tokenizing">
    <mat-progress-spinner mode="indeterminate" [diameter]="30"></mat-progress-spinner>
  </div>
  <div *ngIf="tokenizedCard?.token === 'EXPIRED'" class="checkbox-cls">
    <mat-checkbox color="primary" (change)="cardSelection($event)">Use <span class="type">{{ tokenizedCard.type }}</span> ending in <span class="last-four primary-color">{{ tokenizedCard.lastFour }}</span></mat-checkbox>
  </div>
  <form [formGroup]="billingForm" fxLayout="row wrap" fxLayoutAlign="space-evenly start" class="mt-1 payment-method-form" [class.disable]="useExistingCard">
    <div fxFlex="100" fxLayout="column" [ngClass]="{'collect-js-hidden': submittingPayment}">
      <!-- payment info -->
      <!-- <div class="pt-xs">
        Pay with:
        <mat-button-toggle-group aria-label="Select an option" [(ngModel)]="paymentType" name="paymentType"
                                 (change)="loadCollectJs()" [ngModelOptions]="{standalone: true}" [disabled]="submittingPayment">
          <mat-button-toggle [checked]="paymentType === achType" value="ACH">ACH</mat-button-toggle>
          <mat-button-toggle [checked]="paymentType === cardType" value="CARD">Card</mat-button-toggle>
        </mat-button-toggle-group>
      </div> -->
      <div [ngClass]="{'collect-js-hidden': isAchType()}" class="collect-input-placeholder">
        <div id="cc-number"></div>
      </div>

      <div fxLayout>
	      <div fxFlex="50" [ngClass]="{'collect-js-hidden': isAchType()}" class="collect-input-placeholder">
	        <div id="cc-exp"></div>
	      </div>
	      <div fxFlex=50 [ngClass]="{'collect-js-hidden': isAchType()}" class="collect-input-placeholder">
	        <div id="cvv"></div>
	      </div>
	    </div>
      <!-- <div [ngClass]="{'collect-js-hidden': isCardType()}" class="collect-input-placeholder">
        <div id="check-acct"></div>
      </div>
      <div [ngClass]="{'collect-js-hidden': isCardType()}" class="collect-input-placeholder">
        <div id="check-aba"></div>
      </div>
      <div [ngClass]="{'collect-js-hidden': isCardType()}" class="collect-input-placeholder">
        <div id="check-name"></div>
      </div> -->

      <!-- <div fxFlex="100" fxFlex.gt-sm="50" class="px-1">
	      <mat-form-field class="w-100">
	        <input matInput placeholder="Name on card" formControlName="cardName" required>
	        <mat-error *ngIf="billingForm.controls.cardName.errors?.required">Card name is required</mat-error>
	      </mat-form-field>
	    </div> -->
      <!-- <button mat-raised-button color="accent" id="pay-button" type="button" (click)="enterPaymentInfo($event)" class="mt-1"
              [disabled]="submittingPayment || billingForm.invalid">
        Add Payment Method
      </button> -->
    </div>
  </form>
  <p *ngIf="paymentError" class="mat-text-warn text-center pt-1">
    The following error occurred: {{paymentError}}. Please try again later.
  </p>
</div>