import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ProductCategory } from '@app/_core/models';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent {

  @Input() categories: ProductCategory[];
  @Input() categoryParent_id: string = "";
  @Input() selectedCategory: string = "";
  @Output() change: EventEmitter<any> = new EventEmitter();
  mainCategories;

  constructor() {
   }

  public ngDoCheck() {
    if(this.categories && !this.mainCategories) {
      this.categories.sort(function(a,b){
        return a.dateCreated.seconds*1000 - b.dateCreated.seconds*1000;
      });
      
      this.mainCategories = this.categories.filter(category => {
        if (this.categoryParent_id)
          return category.parent_id == this.categoryParent_id;
        else
          return !category.parent_id;
      }).sort((a,b) => a.name.localeCompare(b.name));
    }
  }

  public stopClickPropagate(event: any){
    if(window.innerWidth < 960){
      event.stopPropagation();
      event.preventDefault();
    }    
  }

  public changeCategory(id: string){
    this.change.emit(id);
  }
}