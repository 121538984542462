import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

import { from, Observable, Subject } from "rxjs";
import { map, share, takeWhile } from "rxjs/operators";

import {
  AmptifyCard,
  AmptifyAddress
} from "@app/_core/models/payments";
import { Account } from "@app/_core/models/firestore";
import { AccountBillingService, AccountService } from "@app/_core/services/firestore";
import { NgxSpinnerService } from "ngx-spinner";
import { ConfirmDialogComponent } from "@app/_shared/dialogs";

var CollectJS = window["CollectJS"];

@Component({
  selector: "amptify-payment-methods",
  templateUrl: "./payment-methods.component.html",
  styleUrls: ["./payment-methods.component.scss"],
})
export class PaymentMethodsComponent implements OnInit, OnDestroy {
  private _onDestroy = new Subject();
  //private _tokenizedAch: AmptifyAch;
  private _tokenizedCard: AmptifyCard;
  //private _tokenizedInvoice: AmptifyInvoice;
  private _amptifyAddress: AmptifyAddress;

  // @Input()
  // set tokenizedAch(card: AmptifyAch) {
  //   this._tokenizedAch = card;
  //   this.ref.markForCheck();
  // }
  @Input()
  set tokenizedCard(card: AmptifyCard) {
    this._tokenizedCard = card;
    this.ref.markForCheck();
  }
  // @Input()
  // set tokenizedInv(inv: AmptifyInvoice) {
  //   this._tokenizedInvoice = inv;
  //   this.ref.markForCheck();
  // }
  @Input()
  set amptifyAddress(add: AmptifyAddress) {
    this._amptifyAddress = add;
    this.ref.markForCheck();
  }

  @Input() buttonText: string = "Add Card";
  @Input() achText: string = "Add Bank Account";
  @Input() invText: string = "Pay Via Invoice";
  @Input() selectedPrimary: "ach" | "card" | "inv";

  //@Input() company: Company;

  @Output() awaitCollect = new EventEmitter<string>();

  //@Output() achCapture = new EventEmitter<AmptifyAch>();
  @Output() cardCapture = new EventEmitter<AmptifyCard>();
  //@Output() invCapture = new EventEmitter<AmptifyInvoice>();
  @Output() primaryChange = new EventEmitter<"ach" | "card" | "inv">();

  collectLoaded: boolean;

  actionPerm$: Observable<boolean>;
  companyBilling: any;
  declinedCards: [];
  disableInvoicePayment: boolean = false;

  isSuper: boolean;

  public currentUser: Account;
  constructor(
    private accountService: AccountService,
    // private swipesumDeleteService: SwipesumDeleteService,
    // private companyBillingService: CompanyBillingService,
    // private loaderService: AppLoaderService,
    // private permissionService: PermissionService,
    private accountBillingService: AccountBillingService,
    private spinner: NgxSpinnerService,
    private ref: ChangeDetectorRef,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
    this.accountService.currentAccountInfo
      .subscribe(account => {
        this.currentUser = account;
        if (this.currentUser)
          this.getAccountBillingInfo();
      })

  }

  ngOnDestroy(): void {
    this._onDestroy.next(null);
    this._onDestroy.complete();
  }

  // get tokenizedAch(): AmptifyAch {
  //   return this._tokenizedAch;
  // }
  get tokenizedCard(): AmptifyCard {
    return this._tokenizedCard;
  }
  // get tokenizedInv(): AmptifyInvoice {
  //   return this._tokenizedInvoice;
  // }
  get amptifyAddress(): AmptifyAddress {
    return this._amptifyAddress;
  }

  ngOnInit(): void {
    // this.actionPerm$ = from(this.permissionService.checkActionPermission(Permissions.COMPANIES_SELF_ADMIN)).pipe(
    //     map(res => res),
    //     share()
    //   );
    this.getDeclinedCards();

    //this.isSuper = this.accountService.checkRole() === 'super';
  }

  /**
   * Need to define how declined cards are handled for a user
   * 
   * @since 0.0.0
   */
  async getDeclinedCards() {
    // if (this.company && this.company?._id) {
    //   this.companyBilling = await this.companyBillingService.getData(this.company._id)
    //   if (this.companyBilling &&
    //   this.companyBilling?.declinedCards &&
    //   this.companyBilling?.declinedCards?.length) {
    //     this.declinedCards = this.companyBilling.declinedCards;
    //   }
    //   this.checkInvoiceDate();
    // }
  }

  /**
   * Load external library (no npm available)
   */
  private configCollectJs(type: string = "cc") {
    CollectJS.configure({
      variant: "lightbox",
      buttonText: "Proceed",
      styleSniffer: true,
      paymentType: type,
      callback: async (token) => {
        this.awaitCollect.emit("completing");
        if (token?.card?.type) {
          this.selectedPrimary = "card";
          this.saveCardInfo({
            type: token.card.type,
            lastFour: token.card.number.slice(-4),
            token: token.token
          });
        }
        // } else if (token?.check?.account) {
        //   this.tokenizedAch = {
        //     name: token.check.name,
        //     account: token.check.account.slice(-4),
        //     token: token.token,
        //   };
        //   this.selectedPrimary = "ach";
        //   this.achCapture.emit(this.tokenizedAch);
        // }
        this.ref.detectChanges();
        this.ref.markForCheck();
        //this.finishSubmit(token)
      },
    });
  }

  async startCollectJS(type: string = "cc") {
    if (type == "inv") {
      //await this.generateInvoice();
    } else {
      this.configCollectJs(type);
      try {
        this.awaitCollect.emit("collecting");
        CollectJS.startPaymentRequest();
      } catch (err) {
        console.error(err);
      }
    }
  }

  /**
   * Select the primary payment method between ach or card
   *
   * @since 0.9.17
   */
  selectPrimary(type: "ach" | "card" | "inv") {
    if (type !== this.selectedPrimary) {
      this.selectedPrimary = type;
      this.primaryChange.emit(this.selectedPrimary);
    }
  }

  /**
   * Confirm and delete a payment method
   *
   * @todo need to make this work for deleting user card payments
   * @since 0.9.17
   */
  deletePayment(type: "ach" | "card" | "inv") {
    let message =
      "Are you sure you want to delete this " + type + " payment method?";

    // if (this.selectedPrimary == type) {
    //   if (this.tokenizedAch?.token && this.tokenizedCard?.token) {
    //     message += " This will also change your default pay type to ";
    //     message += type == "ach" ? "card" : "ach";
    //     message += ".";
    //     changeDefault = true;
    //   } else {
    //     message +=
    //       " If you are currently subscribed to a sponsored plan this could cause an interruption at the next billing cycle.";
    //   }
    // }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Confirm Action",
        message
      }
    });
    dialogRef.afterClosed().subscribe(async (dialogResult) => {
      if (dialogResult) {
        try {
          this.spinner.show();
          await this.saveCardInfo({ type: '', lastFour: '', token: '' }, true);
          this.spinner.hide();
        } catch (error) {
          this.spinner.hide();
          this.snackBar.open(error?.message, '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
        }
      }
    });
  }

  // dialogRef.afterClosed().subscribe(async (confirm) => {
  //   if (confirm) {
  //     // this.loaderService.open('Deleting payment method...');
  //     // Do the delete action
  //     let doc = null;
  //     if (type !== "inv") {
  //       // doc = await this.swipesumDeleteService.create({
  //       //   company_id: this.company._id,
  //       //   vaultType: type,
  //       // });
  //     }

  //     if (doc?._id && type !== "inv") {
  //       // this.swipesumDeleteService
  //       //   .watch(doc._id)
  //       //   .pipe(takeWhile((x) => !x.success && !x.firestoreError, true))
  //       //   .subscribe((data) => {
  //       //     if (data.success) {
  //       //       this.loaderService.close();
  //       //       this.snackBar.open("Payment method has been deleted", null, {
  //       //         duration: 3000,
  //       //       });

  //       //       if (type == "ach") this._tokenizedAch = null;
  //       //       else this._tokenizedCard = null;

  //       //       if (changeDefault)
  //       //         this.selectPrimary(type == "ach" ? "card" : "ach");

  //       //       this.ref.detectChanges();
  //       //     } else if (data.firestoreError) {
  //       //       this.loaderService.close();
  //       //       this.snackBar.open(data.firestoreError, null, {
  //       //         duration: 5000,
  //       //       });
  //       //     }
  //       //   });
  //     }
  //     //this.loaderService.close();
  //   }
  // });
  // }

  private async getAccountBillingInfo() {
    this.spinner.show();
    const billingInfo = await this.accountBillingService.getData(this.currentUser?._id);
    if (billingInfo) {
      const { type = '', lastFour = '', token = '' } = billingInfo;
      this.tokenizedCard = { type, lastFour, token };
    }
    this.cardCapture.emit(this._tokenizedCard);
    this.selectedPrimary = "card";
    this.spinner.hide();
  };

  private async saveCardInfo(data: AmptifyCard, isdelete: boolean = false) {
    this.spinner.show();
    await this.accountBillingService.update(this.currentUser?._id, {...data, customerChange: true});
    await this.getAccountBillingInfo();
    this.snackBar.open(`Your card ${isdelete ? 'deleted' : 'saved'} successfully!`, '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
  };
}
