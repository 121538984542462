import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-return-order-dialog',
  templateUrl: './return-order-dialog.component.html',
  styleUrls: ['./return-order-dialog.component.scss']
})
export class ReturnOrderDialogComponent implements OnInit {

  items = new FormControl(null, Validators.required);
  itemsList: string[] = [];

  constructor(public dialogRef: MatDialogRef<ReturnOrderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    const itemsList = this.data?.cartItems || [];
    this.itemsList = itemsList.filter(data => data?.return !== 'requested') || []
  }

  onConfirm(): void {
    if (this.items.value) {
      this.dialogRef.close(this.items.value);
    }
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
