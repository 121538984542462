<div class="upload-area" #uploadArea (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)"
  (drop)="onDropSuccess($event)">
  <div class="upload-button">
    <button type="button" mat-raised-button (click)="hiddenFileInput.click()">{{ buttonText }}</button>
    <!-- <p>{{ pdfFile?.name }}</p> -->
  </div>
  <label class="dragdrop">{{ alternateText }}</label>
  <input hidden type="file" #hiddenFileInput [accept]="fileTypes" (change)="onChange($event)" multiple>

  <mat-error *ngIf="errorMessage">{{ errorMessage }}</mat-error>
</div>