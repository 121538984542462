import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Account, Product } from '@app/_core/models';

import { AccountShoppingCartService } from '@app/_core/services/firestore/_products/account-shopping-cart.service';
import { AccountService } from '@app/_core/services/firestore';

import { BreakpointObserverService } from '@app/_shared/services';

@Component({
  selector: 'app-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss']
})
export class ControlsComponent implements OnInit, OnDestroy {
  private _onDestroy = new Subject();
  private _product: Product;

  @Input() 
  set product(product: Product) {
    this._product = product;
  }

  @Input() type: string;
  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();
  @Output() onQuantityChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() cartData: any;

  public count: number = 1;
  public align = 'center center';
  private outOfStockMessage = 'This product is out of stock';
  cartList: any;
  countNumber: number = 0;
  public currentUser: Account;

  onMobile: boolean;

  constructor(
    public snackBar: MatSnackBar,
    private cartService: AccountShoppingCartService,
    private accountService: AccountService,
    private breakpointObserver:BreakpointObserverService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  get product(): Product { return this._product }

  ngOnInit() {
    this.layoutAlign();
    this.accountService.currentAccountInfo
      .pipe(takeUntil(this._onDestroy))
      .subscribe(async account => {
        this.currentUser = account;
      })

    this.breakpointObserver.screenSizeObserver
      .pipe(takeUntil(this._onDestroy))
      .subscribe(currentBP => {
        this.onMobile = currentBP === 'xs' || currentBP === 'sm';
    })

  }

  ngOnDestroy() {
    this._onDestroy.next(null);
    this._onDestroy.complete();
  }

  public layoutAlign() {
    if (this.type == 'all') {
      this.align = 'space-between center';
    }
    else if (this.type == 'wish') {
      this.align = 'start center';
    }
    else {
      this.align = 'center center';
    }
  }



  public async updateQuantity(isIncrement: boolean) {
    try {
      let variant = this.getVariant();

      const status = await this.cartService.quantityUpdate(this.currentUser?._id, this.product, isIncrement, variant);
      if (status) {
        this.snackBar.open(`${isIncrement ? 'Added to' : 'Removed from'} cart`, 'OK', { panelClass: 'success', verticalPosition: 'top', duration: 5000 });
      } else {
        this.snackBar.open(this.outOfStockMessage, 'OK', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
      }
      this.cartService.getAmptifyDtxOffer();
    } catch (error) {
      this.snackBar.open(error.message, 'OK', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
    }
  }

  public openProductDialog(event) {
    this.onOpenProductDialog.emit(event);
  }

  public async addToCart(product: Product, quantity: number) {
    try {
      let variant = this.getVariant();

      const status = await this.cartService.addToCart(this.currentUser?._id, product, quantity, variant);
      if (status) {
        this.snackBar.open('Added to cart', 'OK', { panelClass: 'success', verticalPosition: 'top', duration: 5000 });
      } else {
        this.snackBar.open(this.outOfStockMessage, 'OK', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
      }
      this.cartService.getAmptifyDtxOffer();
    } catch (error) {
      console.error('[ControlsComponent] addToCart', error);
      this.snackBar.open(error.message, 'OK', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
    }
  }

  private getVariant() {
    let variant;

    if (this.product?.sizeColor?.length) {
      variant = this.product.sizeColor.find(x => x.selected);
    } else if (this.product?.size?.length) {
      variant = this.product.size.find(x => x.selected);
    } else if (this.product?.color?.length) {
      variant = this.product.color.find(x => x.selected);
    }

    return variant;
  }

  public viewProduct() {
    this.router.navigate(['products', this.product._id, this.product.name], {relativeTo: this.route});
  }
}