/**
 * Service for interacting with accounts in Firestore, extends BaseFirestoreService
 * This is in conjunction with the auth service not replacing it
 *
 * @todo type an account
 * @since 0.0.1
 */

import { EventEmitter, Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AccountShoppingCart, Product } from '@app/_core/models/firestore';
import { ApiFirestoreService } from '@app/_core/services/api-firestore/api-firestore.service';
import { ProductService } from '.';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ProductsCompanyService } from '../products-company/products-company.service';
import { CompanyMarketplaceService } from '../company-marketplace/company-marketplace.service';

import { LocalStorageService } from '@app/_shared/services';

@Injectable({
  providedIn: 'root'
})
export class AccountShoppingCartService extends ApiFirestoreService<AccountShoppingCart> {
  private cartSubscription: Subscription;

  private cartSubject = new BehaviorSubject<AccountShoppingCart>(null);
  currentCart = this.cartSubject.asObservable();

  private archiveCart = new BehaviorSubject<AccountShoppingCart>(null);

  cartList: AccountShoppingCart;
  public shouldDtxOfferOpen = new EventEmitter<boolean>();
  public noThanksDtxOffer: boolean = false;
  private company_id: string = 'amptify';
  public cart_id: string;

  private cartInit: boolean;
  private localCartHandled: boolean;

  constructor(afs: AngularFirestore,
    private productService: ProductService,
    private productsCompanyService: ProductsCompanyService,
    private companyMarketPlace: CompanyMarketplaceService,
    private localStorageService: LocalStorageService,
    private spinner: NgxSpinnerService) {
    super('accountShoppingCarts', afs);
    const companyData = this.companyMarketPlace.getMarketplaceSettings();
    if (companyData) {
      this.company_id = companyData._id;
    }
  }

  /**
   * Initialize the cart and watch with a subscription
   * 
   * @since 0.0.0
   */
  async initCart(account_id?: string) {
    const localCartKey = `cart[${this.company_id}]`;
    const localCartString = this.localStorageService.getData(localCartKey);

    if (!account_id) {
      this.cartSubscription = this.localStorageService.observer$[`cart[${this.company_id}]`].subscribe(async cart => {
        this.handleCartData(JSON.parse(cart));
      });
    } else if (account_id) {
      await this.getCart_id(account_id);

      if (!this.cartInit && this.cart_id) {
        this.cartInit = true;

        this.cartSubscription = this.watch(this.cart_id).subscribe(async cart => {
          await this.handleCartData(cart);

          if (localCartString && !this.localCartHandled) {
            this.localCartHandled = true;
            const localCart = JSON.parse(localCartString);

            if (localCart?.cartItems?.length) {
              for (let item of localCart.cartItems) {
                item._id = item.product_id;
                await this.addToCart(account_id, item, item.quantity);
              }
            }

            this.localStorageService.removeData(localCartKey);  
          }
        });
      }
    }
  };

  private async handleCartData(cart) {
    const list = [],
          ids = cart?.cartItems?.map(item => item.product_id) || [],
          products = ids.length ? await this.productService.getGroup(ids) : [];

    /* Added Code For Product Company Get Products */
    const customizedProducts = await this.productsCompanyService.getCustomizedProducts(ids) || [];
    /* Code End */

    const networkCodes = [];

    cart?.cartItems?.map((item, index) => {
      let data = products?.find(product => product._id == item.product_id);

      if (data?._id) {
        /* Added Code For Product Company, Replace Information in Cart Products */
        const customizedProduct = customizedProducts?.find(customProduct => customProduct.product_id == item.product_id);
        if (customizedProduct) {
          delete customizedProduct._id;
          data = { ...data, ...customizedProduct }
        }
        /* Code End */

        /* Get the size and color data if needed */
        let sizeColorData;
        if (!data.external_id) {
          // Without a top level ID that means there are product variants
          const arrayName = data?.sizeColor?.length ? 'sizeColor' : data?.size?.length ? 'size' : data?.color?.length ? 'color' : null;

          if (arrayName) {
            const info = (data[arrayName] as any).find(x => x.external_id === item.external_id);

            if (info) {
              sizeColorData = {
                sizeName: arrayName === 'sizeColor' ? info.sizeName : arrayName === 'size' ? info.name : null,
                colorName: arrayName === 'sizeColor' ? info.colorName : arrayName === 'color' ? info.name : null,
                images: info.images?.length ? info.images : null
              }

              if (!sizeColorData.images) delete sizeColorData.images;

              data.external_id = info.external_id;
            }
          }
        }

        let pushData = { ...item, _id: data._id, amptifyPrice: data.amptifyPrice, price: data.price, images: data.images, name: data.name, cartCount: data.cartCount || 0, external_id: data.external_id, fulfillmentMethod: data.fulfillmentMethod };
        if (sizeColorData) pushData = {...pushData, ...sizeColorData};
        if (data?.amptifyNetworkCode) {
          pushData = {...pushData, amptifyNetworkCode: data.amptifyNetworkCode};
          if (!networkCodes.includes(data.amptifyNetworkCode)) networkCodes.push(data.amptifyNetworkCode);
        } 

        list.push(pushData);
      }
    });

    if (networkCodes?.length) cart = {...cart, networkCodes};

    this.cartList = cart;
    this.cartSubject.next({ ...cart, cartItems: list });

    return;
  }

  private async getCart_id(account_id: string) {
    const currentCart = await this.getQueryData({ where: [{ field: 'account_id', comparator: '==', value: account_id }, { field: 'company_id', comparator: '==', value: this.company_id }] });
    this.cart_id = currentCart?.[0]._id;
  };

  async addToCart(account_id: string, product: Product, quantity: number, variant?: any) {
    // if (!account_id)
    //   throw new Error('You must be logged in to add an item to your cart.');

    this.spinner.show();
    let { cartCount = 0, availabilityCount = 0 } = await this.productService.getData(product._id) || {};

    /* Added Code For Product Company Cart Count and Availability Count */
    const [productCompany] = await this.productsCompanyService.getProductsOfCompany(product._id) || [];
    if (productCompany) {
      const { cartCount: productCompanyCartCount = 0, availabilityCount: productCompanavailabilityCount = 0 } = productCompany;
      cartCount = productCompanyCartCount;
      //availabilityCount = productCompanavailabilityCount;
    }
    /* Code End */

    const external_id = variant ? variant.external_id : product.external_id;
    
    if ((cartCount + quantity)) {// <= availabilityCount) {
      const isExistProductInCart = this.cartList?.cartItems?.find(item => item.external_id == external_id);
      if (!!isExistProductInCart) {
        const cart = this.cartList?.cartItems.map(item => {
          if (isExistProductInCart.external_id === item.external_id) {
            item.quantity = item.quantity + quantity
          }
          return item;
        });

        if (account_id)
          await this.update(this.cart_id, { cartItems: cart });
        else
          this.localStorageService.saveData(`cart[${this.company_id}]`, JSON.stringify({...this.cartList, cartItems: cart}));
      } else {
        let data = {
          product_id: product._id,
          external_id: external_id,
          quantity: quantity
        }

        if (account_id) {
          const accountShoppingCart = this.cart_id && await this.getData(this.cart_id);

          if (accountShoppingCart) {
            await this.addToArray(this.cart_id, 'cartItems', data)
          } else {
            await this.create({ cartItems: [data], company_id: this.company_id, account_id: account_id });
            await this.initCart(account_id);
          }
        } else {
          const items = this.cartList?.cartItems?.length ? [...this.cartList.cartItems, data] : [data];
          this.localStorageService.saveData(`cart[${this.company_id}]`, JSON.stringify({ cartItems: items, company_id: this.company_id, account_id: account_id }))
        }
      }

      if (account_id) {
        if (productCompany)
          await this.productsCompanyService.update(productCompany._id, { cartCount: cartCount + quantity });
        else
          await this.productService.update(product._id, { cartCount: cartCount + quantity });
      }

      this.spinner.hide();
      return true;
    }
    else {
      this.spinner.hide();
      return false;
    }
  }

  async quantityUpdate(account_id: string, product: any, isIncrement: boolean, variant?: any) {
    this.spinner.show();
    const external_id = variant?.external_id ? variant.external_id : product.external_id;

    const isExistProductInCart = this.cartList?.cartItems?.find(item => item.external_id == external_id);
    if (!!isExistProductInCart) {
      const cart = this.cartList?.cartItems.filter(item => {
        if (external_id === item.external_id) {
          item.quantity = isIncrement ? item.quantity + 1 : item.quantity - 1;
        }
        if (item.quantity) {
          return item;
        }
      });

      if (account_id) {
        await this.update(this.cart_id, { cartItems: cart });
        const [productCompany] = await this.productsCompanyService.getProductsOfCompany(product._id) || [];
        if (productCompany) {
          await this.productsCompanyService.update(productCompany._id, { cartCount: isIncrement ? product.cartCount + 1 : product.cartCount - 1 });
        } else {
          await this.productService.update(product._id, { cartCount: isIncrement ? product.cartCount + 1 : product.cartCount - 1 });
        }
      } else {
        this.localStorageService.saveData(`cart[${this.company_id}]`, JSON.stringify({...this.cartList, cartItems: cart}));
      }
      
      this.spinner.hide();
      return true;
    } else {
      return await this.addToCart(account_id, product, 1, variant);
    }
  }

  async removeFromCart(account_id: string, product_id: string, external_id: string, quantity: number) {
    this.spinner.show();

    const cartItems = this.cartList.cartItems?.filter(item => item.external_id !== external_id);
    
    /* Added Code For Product Company Cart Count and Availability Count */
    if (account_id) {
      await this.update(this.cart_id, { cartItems });

      const [productCompany] = await this.productsCompanyService.getProductsOfCompany(product_id) || [];
      if (productCompany) {
        await this.productsCompanyService.update(productCompany._id, { cartCount: productCompany.cartCount - quantity });
      } else {
        const { cartCount = 0 } = await this.productService.getData(product_id) || {};
        await this.productService.update(product_id, { cartCount: cartCount - quantity });
      }
    } else {
      this.localStorageService.saveData(`cart[${this.company_id}]`, JSON.stringify({...this.cartList, cartItems: cartItems}));
    }

    this.spinner.hide();
  }

  async clearCart(account_id: string) {
    this.spinner.show();
    try {
      const cartItems = this.cartList.cartItems;

      if (account_id) {
        await this.update(this.cart_id, { cartItems: [], amptifyDtxOffer: false });
        for (let index = 0; index < cartItems.length; index++) {
          const { product_id, quantity } = cartItems[index];

          /* Added Code For Product Company Cart Count and Availability Count */
          const [productCompany] = await this.productsCompanyService.getProductsOfCompany(product_id) || [];
          if (productCompany) {
            await this.productsCompanyService.update(productCompany._id, { cartCount: productCompany.cartCount - quantity });
          } else {
            const { cartCount = 0 } = await this.productService.getData(product_id) || {};
            await this.productService.update(product_id, { cartCount: cartCount - quantity });
          }
        }
      } else {
        this.localStorageService.removeData(`cart[${this.company_id}]`);
      }
      
    } catch(err) {
      console.error('[AccountShoppingCartService] clearCart', err);
    }

    this.noThanksDtxOffer = false;
    this.spinner.hide();
  }

  /**
   * This is intended when an order is placed before payment is succesful. If the payment fails we can reinitialize the cart from this value
   *
   * @since 0.1.0
   */
  archiveForClear() {
    const currentCart = this.cartSubject.getValue();
    this.localStorageService.removeData(`cart[${this.company_id}]`);

    this.archiveCart.next(currentCart);

    return this.clearCart(currentCart?.account_id);
  }

  /**
   * Restore the cart from the archive, this should happen on a failed payment
   * 
   * @since 0.1.0
   */
  restoreArchivedCart(account_id: string) {
    const archived = this.archiveCart.getValue();

    if (archived?.cartItems?.length) {
      for (let item of archived.cartItems) {
        this.addToCart(account_id, item as any, item.quantity);
      }
    }
  }

  /**
   * Clear the archived cart
   * 
   * @since 0.1.0
   */
  clearArchivedCart() {
    this.archiveCart.next(null);
  }

  /**
   * Logout and clear the cart subscription
   */
  doLogout() {
    if (this.cartSubscription) this.cartSubscription.unsubscribe();
    this.cartList = null;
    this.cartSubject.next(null);
    this.cartInit = false;
    this.localCartHandled = false;
    this.initCart();
  }

  /**
   * Emit the signal to trigger the Amptify DTx offer
   * 
   * @todo enable this again
   */
  getAmptifyDtxOffer() {
    // if (!this.cartList?.amptifyDtxOffer && !this.noThanksDtxOffer)
    //   this.shouldDtxOfferOpen.emit(true);
  }
}
