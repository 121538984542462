<div *ngIf = "product?._id" fxLayout="row" fxLayout.xs="column" [fxLayoutAlign]="align" class="text-muted">
  <div *ngIf="(type=='all' || type=='wish' || type=='cart')" class="all-wish-wrap p-16" [class.desktop]="!onMobile">
    <div class="d-flex flex-center">
      <!-- <span *ngIf="type!='wish'" class="fw-500">Quantity:</span>  -->
      <button mat-icon-button matTooltip="Remove" (click)="updateQuantity(false)"><mat-icon>remove</mat-icon></button>
      <span> {{product?.quantity || 0}} </span>
      <button mat-icon-button matTooltip="Add" (click)="updateQuantity(true)"><mat-icon>add</mat-icon></button>
    </div>

    <button *ngIf="type != 'cart'" mat-raised-button color="accent" class="add-to-cart" (click)="addToCart(product, 1)"><mat-icon>shopping_cart</mat-icon> ADD TO CART</button>
  </div>
  <div [ngClass]="{'control-icons': type != 'all'}" *ngIf="type!='wish' && type != 'all' && type != 'cart'">  
    <button mat-flat-button matTooltip="Add to cart" (click)="addToCart(product, 1)">ADD TO CART</button>
    <button mat-flat-button (click)="viewProduct()">VIEW</button>
    <!-- <button *ngIf="type!='all'" mat-icon-button matTooltip="Quick view" (click)="openProductDialog(product)"><mat-icon>remove_red_eye</mat-icon></button> -->
    <!-- <button *ngIf="type!='all'" mat-icon-button [matMenuTriggerFor]="helpIcon">
      <mat-icon>help</mat-icon>
    </button> -->
    <mat-menu #helpIcon="matMenu">
      <div class="helpIcon">
        Do you have questions about setting up this device? Schedule a visit to discuss this with a specialist.
      </div>
    </mat-menu>
  </div>
</div>
<div *ngIf="product?.availabilityCount == 0 && type=='all'" class="bg-warn p-1 mt-2">
  Sorry, this item is unavailable. Please choose a different one.
</div>