import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { DiscountClub } from '@app/_core/models/firestore/discounts-networks';
import { ApiFirestoreService } from '@app/_core/services/api-firestore/api-firestore.service';

@Injectable({
    providedIn: 'root'
})
export class DiscountClubService extends ApiFirestoreService<DiscountClub> {
    constructor(public afs: AngularFirestore) {
        super('discountClubs', afs);
    }
}