import { Injectable } from '@angular/core';

import * as salesTaxRates from 'sales-tax/res/sales_tax_rates.json';

//var SalesTax = require("sales-tax");

interface SalesTaxRates {
  [country: string]: {
    type: string;
    rate: number;
    states?: {
      [state: string]: {
        type: string;
        rate: number;
      };
    };
  };
}

@Injectable({
  providedIn: 'root'
})
export class SalesTaxService {

  private salesTaxRates: SalesTaxRates = salesTaxRates;

  constructor() {}

  /**
   * Returns the sales tax value for a price
   * 
   * @since 0.1.0
   */
  public calculate(price: number, country: string, state?: string): number {
    let taxRate = this.getTaxRate(country, state);
    return Math.floor(price * taxRate);
  }

  getTaxRate(country: string, state?: string): number {
    if (!Object.prototype.hasOwnProperty.call(this.salesTaxRates, country)) {
      console.error(`No tax rate data found for country: ${country}`);
      return 0;
    }

    const countryTaxRate = this.salesTaxRates[country].rate;

    if (state) {
      if (!Object.prototype.hasOwnProperty.call(this.salesTaxRates[country].states, state)) {
        console.error(`No tax rate data found for state: ${state} in country: ${country}`);
        return countryTaxRate || 0;
      }

      return this.salesTaxRates[country].states[state].rate;
    } else {
      return countryTaxRate || 0;
    }
  }

}
