import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { Subject, takeUntil } from 'rxjs';

import { SINGLE_SHIPPING_COST } from '@app/_core/constants/app-constants';

import { Account, AccountShoppingCart } from '@app/_core/models';
import { AccountService, ProductService } from '@app/_core/services/firestore';

import { SalesTaxService } from '@app/_shared/services';

import { AccountShoppingCartService } from '@app/_core/services/firestore/_products/account-shopping-cart.service';

@Component({
  selector: 'amptify-checkout-summary',
  templateUrl: './checkout-summary.component.html',
  styleUrls: ['./checkout-summary.component.scss']
})
export class CheckoutSummaryComponent implements OnInit, OnDestroy {
  private _onDestroy = new Subject();
  private _shippingAddress;

  @Input()
  set shippingAddress(address) {
    this._shippingAddress = address;
    this.updateTaxes();
  }

  public cartList: AccountShoppingCart;
  public subtotal: number = 0;

  private singleShippingCost: number = SINGLE_SHIPPING_COST;
  public shippingCost: number;
  public salesTax: number;
  public grandTotal = 0;

  public showPromoCode: boolean = false;

  constructor(private cartService: AccountShoppingCartService,
              private salesTaxService: SalesTaxService) {
    this.cartService.currentCart
      .subscribe(cartList => {
        this.cartList = cartList;

        this.subtotal = 0;
        for (let index = 0, len = this.cartList?.cartItems?.length; index < len; index++) {
          const item = this.cartList?.cartItems[index];
          this.subtotal += item.price * item.quantity;
        }

        if (this.cartList?.cartItems?.length) {
          const totalFulfillments = [...new Set(this.cartList.cartItems.map(x => x.fulfillmentMethod))];
          this.shippingCost = totalFulfillments?.length * this.singleShippingCost;
        }

        this.salesTax = this.salesTaxService.calculate((this.subtotal + this.shippingCost), "US", null);
        this.grandTotal = this.subtotal + this.shippingCost + this.salesTax;
      })
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this._onDestroy.next(null);
    this._onDestroy.complete();
  }

  private updateTaxes() {
    if (this._shippingAddress?.country) {
      const subtotal = this.subtotal || 0,
            shippingCost = this.shippingCost || 0;

      this.salesTax = this.salesTaxService.calculate((subtotal + shippingCost), this._shippingAddress.country, this._shippingAddress.state);
      this.grandTotal = subtotal + shippingCost + this.salesTax;
    }
  }

}
