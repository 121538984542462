import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/compat/firestore';

import { ApiFirestoreService } from '@app/_core/services/api-firestore/api-firestore.service';

import { AccountPublic } from '@app/_core/models/firestore';

@Injectable({
  providedIn: 'root'
})
export class AccountsPublicService extends ApiFirestoreService<AccountPublic> {

  constructor(afs: AngularFirestore) {
    super('accountsPublic', afs);
  }
}
