/**
 * Service for interacting with accounts in Firestore, extends BaseFirestoreService
 * This is in conjunction with the auth service not replacing it
 *
 * @todo type an account
 * @since 0.0.1
 */

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { ApiFirestoreService } from '@app/_core/services/api-firestore/api-firestore.service';
import { HearingLossLevel } from '@app/_core/models/firestore/products/hearing-loss-level.model';

@Injectable({
  providedIn: 'root'
})
export class HearingLossLevelService extends ApiFirestoreService<HearingLossLevel> {
  constructor(afs: AngularFirestore,
    private analytics: AngularFireAnalytics) {
    super('productHearingLossLevels', afs);
  }
}
