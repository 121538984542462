<mat-card appearance="outlined" *ngFor="let fulfillment of order?.fulfillments; let i = index" class="fulfillment mat-elevation-z0">
  <div class="fulfillment-header d-flex">
    <p>Shipment #{{ i + 1 }}</p>

    <div class="d-flex items-center">
      <button *ngIf="canEditTracking(fulfillment.status)" mat-raised-button color="primary" class="edit-tracking mr-1" (click)="openEditTracking(fulfillment, i)">Edit Tracking Number</button>
      <p class="fulfillment-status">{{ getOrderStatus(fulfillment.status) }}</p>
    </div>
  </div>

  <div class="order-items">
    <div *ngFor="let product_id of fulfillment.fulfilledItem_ids" class="order-item d-flex">

      <img [src]="getProductImage(product_id)" (error)="onImgError($event)">
      <div class="product-detail">
        <ng-container *ngIf="companyData">
          <a [routerLink]="['/'+companyData.slug+'/products', product_id, getProductData(product_id, 'name')]" class="product-name">{{ getProductData(product_id, 'name') }}</a>
        </ng-container>

        <p>{{ getProductData(product_id, 'brandName') }}</p>
        <p>Qty: {{ getProductData(product_id, 'quantity') }}</p>
        <p>Price: {{ getProductLinePrice(product_id) / 100 | currency }}</p>
      </div>
    </div>
  </div>

  <div *ngIf="fulfillment.trackingNumber" class="tracking-info d-flex flex-col">
    <p class="tracking">Shipped with <span class="uppercase">{{ fulfillment.shippingCarrier }}</span></p>
    <p class="trakcing-number">Tracking Number: <span class="cursor-pointer hover:text-red-600" [cdkCopyToClipboard]="fulfillment.trackingNumber">{{ fulfillment.trackingNumber }}</span></p>
  </div>
</mat-card>