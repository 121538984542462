<div class="brands-carousel"> 
    <swiper class="swiper-container h-100" [config]="config">
        <a mat-button routerLink="brands">Show All Brands</a>
        <div class="swiper-wrapper h-100">
          <ng-template *ngFor="let brand of brands" swiperSlide>
            <div class="swiper-slide">
                <div class="p-0">
                    <a [routerLink]="['brands', brand.name]" class="brand-item">
                        <img [attr.data-src]="brand.image" class="swiper-lazy">
                    </a>
                    <div class="swiper-lazy-preloader"></div>
                </div>
            </div>
          </ng-template>
        </div> 
        <button mat-icon-button class="swiper-button-prev swipe-arrow"><mat-icon>keyboard_arrow_left</mat-icon></button>
        <button mat-icon-button class="swiper-button-next swipe-arrow"><mat-icon>keyboard_arrow_right</mat-icon></button> 
    </swiper>
</div>