import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

//import { Permissions } from '@app/_core/constants/app-constants';
import { AuthService } from '@app/_core/services/auth/auth.service';
import { AccountService } from '@app/_core/services/firestore';

@Injectable()
export class SuperAdminGuard implements CanActivate {
  constructor(private auth: AuthService,
              private accountService: AccountService,
              private router: Router) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean>  {
    this.auth.init();
    const role = await this.accountService.awaitRole();

    if (role == 'super')
      return true;

    this.router.navigate(['/']);
    return false;      
  }
}