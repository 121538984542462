<div class="close-btn-outer absolute top-1 right-1">
  <mat-icon color="primary" class="cursor-pointer" (click)="close()">close</mat-icon>
</div>
<div mat-dialog-content>
  <h2>Tracking Information</h2>
  <form [formGroup]="trackingForm" class="flex flex-col">
    <mat-form-field>
      <mat-label>Tracking Number</mat-label>
      <input matInput formControlName="trackingNumber">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Shipping Method</mat-label>
      <mat-select formControlName="shippingCarrier">
        <mat-option *ngFor="let carrier of carriers" [value]="carrier.value">{{ carrier.name }}</mat-option>
      </mat-select>
    </mat-form-field>

    <button mat-raised-button type="submit" color="primary" (click)="save()" [disabled]="trackingForm.invalid">Save</button>
  </form>
</div>