import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private menuTrigger = new Subject();
  public menuTrigger$ = this.menuTrigger.asObservable();

  constructor() { }

  /**
   * Next value for the menu subject
   * 
   * @since 0.1.0
   */
  public triggerMenu(value: any) {
    this.menuTrigger.next(value);
  }
}
