import { Component, OnInit, HostListener, ViewChild, Inject, PLATFORM_ID, ChangeDetectorRef } from '@angular/core';
import { isPlatformBrowser, Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { NgxSpinnerService } from 'ngx-spinner';

import { ObservableInput } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

import { Settings, AppSettings } from '../app.settings';

import { Account, AccountShoppingCart, CompanyMarketplace } from '@app/_core/models';
//import { SidenavMenuService } from '../theme/components/sidenav-menu/sidenav-menu.service';

import { AccountService, AccountShoppingCartService, CompanyMarketplaceService } from '@app/_core/services/firestore';
import { AuthService } from '@app/_core/services/auth/auth.service';

import { MenuService } from '@app/_shared/services';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  providers: []
})
export class PagesComponent implements OnInit {

  //@ViewChild('sidenav', { static: true }) sidenav: any;
  public showBackToTop: boolean = false;
  cartList: AccountShoppingCart;
  public totalPrice: number = 0;
  public currentUser: Account;
  public userRole: string;
  public settings: Settings;
  public totalCartCount: number = 0;
  productData: any;
  quantity: [];
  cartProducts: any[] = [];

  private _onDestroy: ObservableInput<any>;
  cartSideNav = false;
  isActiveSubscriptionStatus: boolean = true;
  companyData: CompanyMarketplace;

  onRootPage: boolean;

  constructor(public appSettings: AppSettings,
    private router: Router,
    private location: Location,
    @Inject(PLATFORM_ID) private platformId: Object,
    private authService: AuthService,
    private accountService: AccountService,
    private cartService: AccountShoppingCartService,
    public spinner: NgxSpinnerService,
    public snackBar: MatSnackBar,
    private companyMarketplaceService: CompanyMarketplaceService,
    private menuService: MenuService,
    private ref: ChangeDetectorRef
  ) {
    this.settings = this.appSettings.settings;

    this.companyData = this.companyMarketplaceService.getMarketplaceSettings();
  }

  async ngOnInit() {
    this.onRootPage = this.isRootPage(this.router.url);

    setTimeout(() => {
      this.settings.theme = 'red';
    });
    this.accountService.currentAccountInfo
      .subscribe(async account => {
        this.currentUser = account;
        this.userRole = this.accountService.checkRole();
        this.isActiveSubscriptionStatus = !!(this.currentUser?.subscriptionStatus === 'active');
      });

    this.cartService.currentCart
      .subscribe(cartList => {
        this.cartList = cartList;
        this.ref.detectChanges();
      })

      this.router.events
        .pipe(filter(event => event instanceof NavigationEnd)).subscribe(val => {
        this.onRootPage = this.isRootPage(val['url']);
      })

    
  }

  ngAfterViewInit() {

    this.cartService.shouldDtxOfferOpen.subscribe((res) => {
      this.cartSideNav = res;
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        //this.sidenav.close();
      }
    });
    //this.sidenavMenuService.expandActiveSubMenu(this.sidenavMenuService.getSidenavMenuItems());
  }

  ngDoCheck(): void {
    this.totalCartCount = 0,
      this.totalPrice = 0;
    if (this.cartList?.amptifyDtxOffer) {
      this.totalCartCount = 1;
    }
    for (let index = 0, len = this.cartList?.cartItems?.length; index < len; index++) {
      const item = this.cartList?.cartItems[index];
      this.totalPrice = this.totalPrice + item.price * item.quantity;
      this.totalCartCount = this.totalCartCount + item.quantity
    }
  }

  /**
   * Takes a url string and returns number of instances of /
   *  This helps us determine if we are on the products page or a different page
   *
   * @since 0.1.0
   */
  private isRootPage(url: string) {
    return url.match(/\//g).length <= 1;
  }

  /**
   * Use location to go back to the previous page
   * 
   * @since 0.1.0
   */
  public goBack() {
    if (window.history.length > 1) {
      this.location.back();
    } else {
      const { slug = '' } = this.companyData || {}
      this.router.navigate([slug]);
    }
  }

  // public async remove(_id: string, quantity: number) {
  //   await this.cartService.removeFromCart(this.currentUser._id, _id, quantity);
  // }

  public clear() {
    this.cartService.clearCart(this.currentUser?._id || null);
  }


  public changeTheme(theme: any) {
    this.settings.theme = theme;
  }

  public stopClickPropagate(event: any) {
    event.stopPropagation();
    event.preventDefault();
  }

  public scrollToTop() {
    var scrollDuration = 400;
    var scrollStep = -window.pageYOffset / (scrollDuration / 20);
    var scrollInterval = setInterval(() => {
      if (window.pageYOffset != 0) {
        window.scrollBy(0, scrollStep);
      }
      else {
        clearInterval(scrollInterval);
      }
    }, 20);
    if (window.innerWidth <= 768) {
      setTimeout(() => {
        if (isPlatformBrowser(this.platformId)) {
          window.scrollTo({
            top: 400, behavior: 'smooth'
          });
        }
      });
    }
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event: any) {
    const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop),
      element = document.getElementsByClassName("cdk-overlay-container");
    if (scrollTop > 0) {
      this.settings.mainToolbarFixed = true;
    }
    else {
      element?.[0]?.firstChild ? this.settings.mainToolbarFixed = true : this.settings.mainToolbarFixed = false;
    }
    ($event.target.documentElement.scrollTop > 300) ? this.showBackToTop = true : this.showBackToTop = false;
  }

  /**
   * Trigger for the menu to send to the service and be used through the router outlet or other app areas
   * 
   * @since 0.1.0
   */
  public menuClick(value: string) {
    this.menuService.triggerMenu(value);
  }

  public closeSubMenus() {
    const { slug = '' } = this.companyData || {}
    if (window.innerWidth < 960) {
      //this.sidenavMenuService.closeAllSubMenus();
    }

    if (this.companyData?.websiteUrl) {
      const pattern = /^((http|https):\/\/)/;
      let url = this.companyData.websiteUrl;

      if (!pattern.test(url)) {
        url = 'https://' + this.companyData.websiteUrl;
      }

      window.open(url, '_self');
    } else 
      this.router.navigate([slug])
  }

  public logout() {
    this.authService.signOut();
  }

  public async addToCart() {
    this.spinner.show();
    this.cartService.shouldDtxOfferOpen.emit(false);
    await this.cartService.update(this.cartService.cart_id, { amptifyDtxOffer: true });
    this.spinner.hide();
    this.snackBar.open(`Added to cart`, '×', { panelClass: 'success', verticalPosition: 'top', duration: 5000 });
  }

  public async noThanks() {
    this.cartService.shouldDtxOfferOpen.emit(false);
    this.cartService.noThanksDtxOffer = true;
  }

  public navigate(id: string, name: string) {
    const slug = this.companyData?.slug;
    if (slug) {
      this.router.navigate([slug + '/products', id, name])
    } else {
      this.router.navigate(['products', id, name])
    }
  }
}
