<mat-sidenav-container>

  <!-- <mat-sidenav [opened]="false" mode="over" #sidenav class="sidenav mat-elevation-z6">
    <button mat-icon-button color="warn" class="close" (click)="sidenav.close()">
      <mat-icon color="warn">close</mat-icon>
    </button>
    <div class="divider"></div>
    <app-sidenav-menu [menuItems]="sidenavMenuItems" [menuParentId]="0"></app-sidenav-menu>
    <div class="divider"></div>
    <div class="text-center py-2">
      <svg class="social-icon" viewBox="0 0 24 24">
        <path
          d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M18,5H15.5A3.5,3.5 0 0,0 12,8.5V11H10V14H12V21H15V14H18V11H15V9A1,1 0 0,1 16,8H18V5Z" />
      </svg>
      <svg class="social-icon" viewBox="0 0 24 24">
        <path
          d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M17.71,9.33C18.19,8.93 18.75,8.45 19,7.92C18.59,8.13 18.1,8.26 17.56,8.33C18.06,7.97 18.47,7.5 18.68,6.86C18.16,7.14 17.63,7.38 16.97,7.5C15.42,5.63 11.71,7.15 12.37,9.95C9.76,9.79 8.17,8.61 6.85,7.16C6.1,8.38 6.75,10.23 7.64,10.74C7.18,10.71 6.83,10.57 6.5,10.41C6.54,11.95 7.39,12.69 8.58,13.09C8.22,13.16 7.82,13.18 7.44,13.12C7.81,14.19 8.58,14.86 9.9,15C9,15.76 7.34,16.29 6,16.08C7.15,16.81 8.46,17.39 10.28,17.31C14.69,17.11 17.64,13.95 17.71,9.33Z" />
      </svg>
      <svg class="social-icon" viewBox="0 0 24 24">
        <path
          d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M19.5,12H18V10.5H17V12H15.5V13H17V14.5H18V13H19.5V12M9.65,11.36V12.9H12.22C12.09,13.54 11.45,14.83 9.65,14.83C8.11,14.83 6.89,13.54 6.89,12C6.89,10.46 8.11,9.17 9.65,9.17C10.55,9.17 11.13,9.56 11.45,9.88L12.67,8.72C11.9,7.95 10.87,7.5 9.65,7.5C7.14,7.5 5.15,9.5 5.15,12C5.15,14.5 7.14,16.5 9.65,16.5C12.22,16.5 13.96,14.7 13.96,12.13C13.96,11.81 13.96,11.61 13.89,11.36H9.65Z" />
      </svg>
    </div>
  </mat-sidenav> -->

  <mat-sidenav *ngIf="(!isActiveSubscriptionStatus || !cartList?.amptifyDtxOffer)" [opened]="cartSideNav" mode="side"
    #cartsidenav class="sidenav mat-elevation-z6" position="end">
    <div>
      <img [src]="'assets/images/amptify/amptify-logo.png'" />
    </div>
    <div class="divider"></div>
    <div class="text-muted">
      <h4>Would you like to add the Amptify DTx to your cart?</h4>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100 dtx">
      <button mat-stroked-button color="primary" (click)="noThanks()">No Thanks</button>
      <button mat-raised-button color="primary" (click)="addToCart()">Add to Cart</button>
    </div>
  </mat-sidenav>

  <mat-toolbar color="primary" id="header-toolbar">
    <mat-toolbar-row fxLayoutAlign="space-between center" class="logo-toolbar theme-container">
      <div class="mobile-menu">
        <button *ngIf="onRootPage; else showBack" mat-icon-button (click)="menuClick('hamburger')"><mat-icon>menu</mat-icon></button>

        <ng-template #showBack>
          <button mat-icon-button (click)="goBack()"><mat-icon>arrow_back</mat-icon></button>
        </ng-template>
      </div>

      <div class="store-logo">
        <a (click)="closeSubMenus()" class="img">
          <img *ngIf="companyData?.logoSrc; else companyName" [src]="companyData?.logoSrc" />

          <ng-template #companyName>
            <span>{{ companyData?.title }}</span>
          </ng-template>
        </a> 
      </div>
      <div fxLayout="row" fxLayoutAlign="end center">
        <div fxLayoutAlign="center center" [@.disabled]="true">
          <button mat-button [routerLink]="['cart']" class="flex-row-button">
            <mat-icon class="mat-icon-lg">shopping_cart</mat-icon>
            <span *ngIf="totalCartCount > 0" class="cart-items-count">{{totalCartCount}}</span>
              <span class="mobile-d-none">Cart</span>
            <!-- <div fxLayout="column" fxLayoutAlign="center center" fxShow="false" fxShow.gt-xs
              class="top-cart">
              <span *ngIf="totalCartCount > 0">
                <bdi>{{totalCartCount}} item<span *ngIf="totalCartCount > 1">s</span></bdi>
              </span>
            </div> -->
          </button>
          <!-- <mat-menu #cartMenu="matMenu" [overlapTrigger]="false" xPosition="before"
            class="top-menu-dropdown cart-dropdown">
            <ng-template matMenuContent>
              <span class="d-block">
                <div fxLayout="row" fxLayoutAlign="space-between center" class="text-muted">
                  <b><span *ngIf="totalCartCount">{{totalCartCount}} ITEM<span
                        *ngIf="totalCartCount > 1">S</span></span>
                    <span *ngIf="!totalCartCount">0 ITEM<span
                        *ngIf="totalCartCount > 1">S</span></span></b>
                  <b><a [routerLink]="['cart']" class="text-muted">VIEW CART</a></b>
                </div>
                <div class="divider mt-1"></div>
                <div *ngIf="totalCartCount == 0" class="py-1 text-muted text-center">You
                  have no items in your shopping cart.</div>
                <mat-list>
                  <mat-list-item *ngFor="let product of cartList?.cartItems" style="cursor: 'pointer';">
                    <h3 (click)="navigate(product._id, product.name)" matLine class="text-muted"> {{product.name}} </h3>
                    <p (click)="navigate(product._id, product.name)" matLine class="text-muted">
                      <bdi>{{product.quantity}} x ${{product.price | number : '1.2-2'}}</bdi>
                    </p>
                    <img (click)="navigate(product._id, product.name)" [src]="product.images && product.images[0]" alt="image">
                    <button mat-icon-button color="warn" class="remove"
                      (click)="remove(product.product_id, product.quantity)" matTooltip="Remove"
                      matTooltipPosition="before">
                      <mat-icon class="mat-icon-sm">close</mat-icon>
                    </button>
                  </mat-list-item>
                  <mat-list-item *ngIf="cartList?.amptifyDtxOffer">
                    <h3 matLine class="text-muted">Amptify DTx Offer</h3>
                  </mat-list-item>
                </mat-list>
                <div *ngIf="totalPrice" class="cart-dropdown-footer">
                  <div class="divider mt-1"></div>
                  <div fxLayout="row" fxLayoutAlign="space-between center" class="text-muted mt-1">
                    <b>TOTAL:</b>
                    <b class="new-price">${{totalPrice | number : '1.2-2'}}</b>
                  </div>
                  <div class="divider mt-1"></div>
                  <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-1">
                    <button mat-mini-fab color="warn" (click)="clear();stopClickPropagate($event)"
                      matTooltip="Clear All" matTooltipPosition="after">
                      <mat-icon>remove_shopping_cart</mat-icon>
                    </button>
                    <a [routerLink]="['checkout']" mat-mini-fab color="primary"
                      matTooltip="Checkout" matTooltipPosition="before">
                      <mat-icon>check</mat-icon>
                    </a>
                  </div>
                </div>
              </span>
            </ng-template>
          </mat-menu> -->
        </div>
        <div fxLayoutAlign="center center" [@.disabled]="true">
          <ng-container *ngIf="currentUser?._id; else noUser">
            <a mat-button [matMenuTriggerFor]="accountMenu" #accountMenuTrigger="matMenuTrigger">
              <mat-icon class="mat-icon-lg account-icon">person</mat-icon> <span class="mobile-d-none">{{currentUser?.firstName}}</span>
            </a>
            <mat-menu #accountMenu="matMenu" [overlapTrigger]="false" xPosition="before"
              class="app-dropdown account">
              <span (mouseleave)="accountMenuTrigger.closeMenu()">
                
                <div fxLayout="row" fxLayoutAlign="space-around center" class="user-info">
                  <img src="assets/images/others/user.jpg" alt="user-image" width="50">
                  <p>{{currentUser?.firstName}} {{currentUser?.lastName}}</p>
                </div>
                <div class="divider"></div>
                <a mat-menu-item routerLink="account">
                  <mat-icon class="mat-icon-sm">settings</mat-icon>
                  <span>Account</span>
                </a>
                <a mat-menu-item routerLink="account/orders">
                  <mat-icon class="mat-icon-sm">inventory_2</mat-icon>
                  <span>Orders</span>
                </a>
                <a *ngIf="userRole === 'super'" mat-menu-item routerLink="/admin">
                  <mat-icon class="mat-icon-sm">admin_panel_settings</mat-icon>
                  <span>Admin Area</span>
                </a>
                <!-- <a mat-menu-item routerLink="">
                  <mat-icon class="mat-icon-sm">help</mat-icon>
                  <span>Help</span>
                </a> -->
                <div class="divider"></div>
                <a mat-menu-item (click)="logout()">
                  <mat-icon class="mat-icon-sm">power_settings_new</mat-icon>
                  <span>{{ 'LOGOUT' | translate }}</span>
                </a>
              </span>
            </mat-menu>
          </ng-container>
              
          <ng-template #noUser>
            <a mat-button routerLink="sign-in">
              <mat-icon class="mat-icon-lg account-icon">person</mat-icon> <span class="mobile-d-none">Account</span>
            </a>
          </ng-template>

        </div>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="theme-container main">
    <!-- <app-breadcrumb></app-breadcrumb> -->
    <router-outlet></router-outlet>
  </div>

  <app-footer></app-footer>

  <div *ngIf="showBackToTop" fxLayout="row" fxLayoutAlign="center center" class="back-to-top transition"
    (click)="scrollToTop()">
    <mat-icon>arrow_upward</mat-icon>
  </div>


</mat-sidenav-container>