import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/compat/firestore';

import { ApiFirestoreService } from '@app/_core/services/api-firestore/api-firestore.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyBillingService extends ApiFirestoreService<any> {

  constructor(afs: AngularFirestore) {
    super('companyBillings', afs);
  }
}
