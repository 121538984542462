import { Component, OnDestroy, OnInit } from '@angular/core';
import { CompanyMarketplaceService } from '@app/_core/services/firestore';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  public lat: number = 40.678178;
  public lng: number = -73.944158;
  public zoom: number = 12;
  public companyData;
  private _onDestroy = new Subject();

  constructor(private companyMarketplaceService: CompanyMarketplaceService) {
    this.companyData = this.companyMarketplaceService.getMarketplaceSettings();
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this._onDestroy.next(true);
    this._onDestroy.complete();
  }

  contactPhoneHref() {
    const phone = 'tel:' + this.companyData?.contactPhone;
    return phone;
  }

  contactEmailHref() {
    const email = 'mailto:' + this.companyData?.contactEmail;
    return email;
  }

  showContact() {
    return this.companyData?.contactAddress || this.companyData?.contactPhone || this.companyData?.contactEmail;
  }

}