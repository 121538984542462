import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, takeUntil, auditTime, tap, filter, take } from 'rxjs/operators';

import {
  Account,
 } from '@app/_core/models/firestore';

import {
  AccountService,
  AccountShoppingCartService,
} from '@app/_core/services/firestore';

import { 
  SelfPayDiscountService,
  SponsoredUserDiscountService,
  NetworkCodeService,
  DiscountClubStatisticsService,
  DiscountClubService } from '@app/_core/services/firestore/_discounts-networks';

import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  readonly api = `${environment.apiURL}/app`;

  user: Observable<firebase.User>;
  private loggingIn = new BehaviorSubject<boolean>(false);

  hasInit: boolean;
  pauseUserInit: boolean;
  pauseLogoutCheck: boolean;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private analytics: AngularFireAnalytics,
    private router: Router,
    private accountService: AccountService,
    private cartService: AccountShoppingCartService,
    private discountClubService: DiscountClubService,
    private discountClubStatisticsService: DiscountClubStatisticsService,
    private networkCodeService: NetworkCodeService,
    private selfPayDiscountService: SelfPayDiscountService,
    private sponsoredUserDiscountService: SponsoredUserDiscountService) {

    this.user = this.afAuth.authState;
    this.accountService.currentAccountInfo.subscribe(account => {
      this.checkDeactivate(account);
    });
  }

  init(): void {
    if (!this.hasInit) {
      this.hasInit = true;
      this.user.subscribe(user => {
        if (user?.uid && !this.pauseUserInit) {
          // Set the user presence and then watch the real-time database for disconnect to go offline
          this.accountService.update(user.uid, { online: true, last_seen: firebase.firestore.FieldValue.serverTimestamp() });
          this.setDisconnect(user.uid);

          // if (!user.emailVerified) {
          //   this.router.navigate(['/verify']);
          // } else {
          // Set the user subject in account service that can be subscribed to around the app for user data
          this.accountService.initAccountInfo(user.uid);
          this.cartService.initCart(user.uid);
        } else if (!this.pauseUserInit) {
          this.cartService.initCart();
        }
      });
    }
    
  };

  async signIn(form: any, route?: string): Promise<any> {
    try {
      const user = await this.afAuth.signInWithEmailAndPassword(form.email, form.password);
      this.loggingIn.next(true);
      this.analytics.logEvent('login', {
        account_id: user.user.uid,
        email: form.email
      });

      const lastUrl = localStorage.getItem('last_url');
      const slug = this.router.url.split('/')[1];

      if (route != 'do not route')
        this.router.navigate([route ? route : lastUrl ? lastUrl : slug]);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  /**
   * Check if the user has been forced to logout or deactivated
   *
   * @since 1.0.8
   */
  async checkDeactivate(account: Account) {
    if (!this.pauseLogoutCheck && account?.forceLogout) {
      this.pauseLogoutCheck = true;
      await this.accountService.update(account._id, {forceLogout: false});
      //this.presentAlert('Signed Out', 'An administrator has remotely signed you out of your account.');
      this.signOut();
    } else if (!this.pauseLogoutCheck && account?.deactivated) {
      this.pauseLogoutCheck = true;
      await this.accountService.update(account._id, {forceLogout: false});
      //this.presentAlert('Signed Out', 'Your account has been deactivated by an administrator.');
      this.signOut();
    }
  }

  /**
   * Sign in with a magic email link
   *
   * @since 0.7.6
   */
  // signInLink(form): Promise<any> {
  //   const actionCodeSettings = {
  //     url: `http://localhost:8100/welcome`,
  //     handleCodeInApp: true
  //   };

  //   return this.afAuth.sendSignInLinkToEmail(form.email, actionCodeSettings);
  // }

  /**
   * Confirm the email link and sign in
   *
   * @since 0.7.6
   */
  // public confirmSignIn(email: string, url: string): Promise<any> {
  //   return this.afAuth.signInWithEmailLink(email, url);
  // }

  /**
   * Register and create the user account document
   *
   * @since 0.0.1
   */
  async register(form: any, route?: string): Promise<any> {
    try {
      this.pauseUserInit = true;

      const {networkCodeDoc, joinedWith}: {networkCodeDoc: any, joinedWith: string} = await this.handleCodeInput(form.orgJoinCode);

      const user = await this.afAuth.createUserWithEmailAndPassword(form.email, form.password);
      const user_id = user.user.uid;

      const { availableSponsoredUserDiscounts, availableSelfPayDiscounts } = await this.handleDiscountsForRegistration(user_id, networkCodeDoc);

      //const registeredOn = new Date().toISOString();
      let data: any = {
        // uid: user.user.uid,
        // email: form.email,
        firstName: form.firstName,
        lastName: form.lastName,
        //dateCreated: registeredOn,
        user_type: 1,
        coins: 0
      }

      //await this.signIn({email: form.email, password: form.password});

      var userDoc = null;

      do {
        setTimeout(() => {}, 500);
        userDoc = await this.accountService.getData(user_id);
      } while (userDoc === null)

      data.online = true;
      data.last_seen = firebase.firestore.FieldValue.serverTimestamp();

      data.payType = 'self';


      if (joinedWith) data.joinedWith = joinedWith;

      if (form?.markeplaceCompany_id) {
        data.registeredFrom = 'marketplace';
        data.registrationMeta = {
          origin: 'marketplace',
          company_id: form.marketplaceCompany_id
        }
      }

      // There is a network code and sponsored user discounts available. The network matched is either device or population, this should place the Sontro users
      if (networkCodeDoc && availableSponsoredUserDiscounts?.length && (networkCodeDoc?.type === 'device-participant-sponsored' || networkCodeDoc?.type === 'population-sponsored')) {
        data.subscriptionStatus = 'active-sponsored-user';
        data.sponsoredUserDiscount_id = availableSponsoredUserDiscounts?.length ? availableSponsoredUserDiscounts[0]?.sponsoredUserDiscount_id : "";
        data.sponsoredUserDiscountClub_id = networkCodeDoc.discountClub_id;
        data.discountEndDate = availableSponsoredUserDiscounts?.length ? availableSponsoredUserDiscounts[0]?.discountEndDate : "";
        data.networkCode_id = networkCodeDoc?._id;
      }

      // Update the users account data
      const accountRes = await this.accountService.update(user_id, data);

      //this.setDisconnect(user_id);

      this.accountService.initAccountInfo(user_id);
      //check account status and update if discountclub statistics if joined through networkcode 

      this.pauseUserInit = false;

      const lastUrl = localStorage.getItem('last_url');
      const slug = this.router.url.split('/')[1];

      if (route != 'do not route')
        this.router.navigate([lastUrl ? lastUrl: slug]);//loader']);
      //this.signOut('email')
      //this.router.navigate(['/verify']);
      this.loggingIn.next(true);
      return Promise.resolve(accountRes);
    } catch(error) {
      this.pauseUserInit = false;
      return Promise.reject(error);
    }
  }

  /**
   * Wait for the user doc to be created with a delay between checks
   *
   * @since 0.0.1
   */
  private async waitForDoc(userDoc_id: any) {
    let userDoc = await this.accountService.getData(userDoc_id);
    if (!userDoc) {
      setTimeout(() => this.waitForDoc(userDoc_id), 1000)
    } else
      return userDoc;
  }

  /**
   * Do auth signout and then clear any subscribptions in other services
   *
   * @since 0.0.0
   */
  async signOut(verify?: string) {
    await this.afAuth.signOut();
    this.cartService.doLogout();
    this.accountService.doLogout();
    this.pauseLogoutCheck = false;

    const slug = this.router.url.split('/')[1];

    if (verify === 'email') {
      return this.router.navigate([slug + '/sign-in'], {queryParams: {verify: verify}});
    } else {
      return this.router.navigate([slug + '/sign-in']);
    }
  }

  resetPassword(email: string): Promise<void> {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  /**
   * Hook to the disconnect event on the firebase real-time to update online status
   *
   * @since 0.1.5
   */
  private setDisconnect(uid: string) {
    const userStatusDatabaseRef = firebase.database().ref('/status/' + uid);

    userStatusDatabaseRef.onDisconnect().set({status: 'offline', last_seen: firebase.database.ServerValue.TIMESTAMP}).then(() => {
      userStatusDatabaseRef.set({status: 'online', last_seen:  firebase.database.ServerValue.TIMESTAMP});
    });
  }

  /**
   * Check the login status
   *
   * @since 0.7.6
   */
  isLoggingIn() {
    return this.loggingIn.getValue();
  }

  /**
   * This is a watcher to reload the user and emit an event when email is verified
   * 
   * @since 0.2.4
   */
  public async refreshUserUntilEmailVerified(interval = 1000) {
    await this.afAuth.user
      .pipe(
        auditTime(interval),
        tap(user => {
          this.afAuth.updateCurrentUser(user);
          user?.reload();
        }),
        filter(user => !!user?.emailVerified),
        take(1),
      ).toPromise();
  }

  /**
   * Handle the code and get relevant information if needed
   * 
   * @since 1.0.0
   */
  private async handleCodeInput(orgJoinCode: string) {
    // let pubUPID: UPIDPublic,
    //     pubOrg: OrganizationPublic,
    let networkCodeDoc: any,
        joinedWith;

    if (orgJoinCode) {
      // This could be a UPID or a general Org Join code
      const codeType = orgJoinCode.substring(10, 12);
      switch (codeType) {
        // case 'JC':
        //   // This is a general org join code
        //   pubOrg = await this.organizationsPublicService.getCodeData(orgJoinCode);

        //   if (!pubOrg) {
        //     console.error('[AuthService] register: Organization not found');
        //     return Promise.reject({message: 'We could not find that Organization.'});
        //   }

        //   joinedWith = 'Org Code';
        //   break;
        // case 'UP': 
        //   // This is a UPID
        //   pubUPID = await this.UPIDPublicService.getCodeData(orgJoinCode);

        //   if (!pubUPID) {
        //     console.error('[AuthService] register: UPID not found');
        //     return Promise.reject({message: 'We could not find that UPID.'});
        //   } else if (pubUPID.claimed) {
        //     console.error('[AuthService] register: UPID has already been claimed.')
        //     return Promise.reject({message: 'That UPID has already been claimed.'});
        //   }

        //   joinedWith = 'UPID';
        //   break;
        default:
          // Check if this could be a network code
          const networkType = orgJoinCode.substring(7,9);

          switch (networkType) {
            // These next two cases do the same thing so we can just let them go through each other without a break
            case 'DP':
            case 'DS':
              networkCodeDoc = await this.networkCodeService.getCodeData(orgJoinCode);

              if (!networkCodeDoc) {
                console.error('[AuthService] register: Device Participant Club not found');
                return Promise.reject({message: 'We could not find that club'});
              }

              joinedWith = 'Device Participant Club';
              break;
            case 'PP':
            case 'PS':
              networkCodeDoc = await this.networkCodeService.getCodeData(orgJoinCode);

              if (!networkCodeDoc) {
                console.error('[AuthService] register: Population Network Club not found');
                return Promise.reject({message: 'We could not find that club'});
              }

              joinedWith = 'Population Network Club';
              break;
            default:
              console.error('[AuthService] register: code provided does not have correct format', orgJoinCode);
              return Promise.reject(new Error('This code does not match any Amptify Code types'));
              break;
          }
          
      }
    }

    return {
      // pubUPID, 
      // pubOrg, 
      networkCodeDoc, 
      joinedWith};
  }

  /**
   * Check the discounts from the network code doc and return available discounts
   */
  private async handleDiscountsForRegistration(user_id: string, networkCodeDoc) {
    let availableSponsoredUserDiscounts: {
      alias: string;
      sponsoredUserDiscount_id: string;
      grantedBy_id: string;
      status: string;
      appliedOn?: any;
      discountEndDate?: any,
      networkCode_id?: any
    }[] = [];
    let availableSelfPayDiscounts: {
      alias: string;
      selfPayDiscount_id: string;
      grantedBy_id: string;
      status: string;
      appliedOn?: any;
      discountEndDate?: any,
      networkCode_id?: any
    }[] = [];

    if (networkCodeDoc) {
      let discountClub;
      if (networkCodeDoc?.discountClub_id) {
        discountClub = await this.discountClubService.getData(networkCodeDoc?.discountClub_id);
      }
      const discount_ids = networkCodeDoc?.discounts;

      if ((networkCodeDoc?.type == "device-participant-self") || (networkCodeDoc?.type == "population-self")) {
        // Self pay network so check for self pay discounts and update
        let totalSelfPayDiscounts: number;
        let newSelfPayDiscount: boolean;
        const selfPayDiscounts = await this.selfPayDiscountService.getGroup(discount_ids);

        if (selfPayDiscounts?.length) {
          totalSelfPayDiscounts = selfPayDiscounts?.length;
          newSelfPayDiscount = true;

          for (let item of selfPayDiscounts) {
            let date;
            if (item?.activeTermDays) {
              date = new Date();
              date.setDate(date.getDate() + item?.activeTermDays);
            }
            availableSelfPayDiscounts.push(
              { 
                alias: discountClub?.name ? discountClub?.name : item?.name,
                grantedBy_id: user_id,
                selfPayDiscount_id: item?._id,
                status: item?.status || "active",
                appliedOn: new Date().toISOString().substr(0, 10),
                discountEndDate: date ? date.toISOString().substr(0, 10) : "",
                networkCode_id: networkCodeDoc?._id
              }
            );
          }

          // create accountDiscount detail document for the registered user
          this.afs.collection('accountDiscounts').doc(user_id).set({
            _id: user_id,
            availableSelfPayDiscounts: availableSelfPayDiscounts,
            totalSelfPayDiscounts: totalSelfPayDiscounts,
            newSelfPayDiscount: newSelfPayDiscount,
            usedNetworkCodes: [networkCodeDoc?._id],
            dateCreated : firebase.firestore.FieldValue.serverTimestamp(),
            network_ids: networkCodeDoc?.network_id ? [networkCodeDoc?.network_id] : []
          });
          this.networkCodeService.update(networkCodeDoc?._id, { codeUsedCount: networkCodeDoc?.codeUsedCount ? (networkCodeDoc?.codeUsedCount + 1) : 1 });
        }
      }else if ((networkCodeDoc?.type == "device-participant-sponsored") || (networkCodeDoc?.type == "population-sponsored")) {
        // This is a sponsored type network so check sponsored discounts
        let totalSponsoredUserDiscounts: number;
        let newSponsoredUserDiscount: boolean;
        const sponsoredUserDiscounts = await this.sponsoredUserDiscountService.getGroup(discount_ids);

        if (sponsoredUserDiscounts?.length) {
          totalSponsoredUserDiscounts = sponsoredUserDiscounts?.length;
          newSponsoredUserDiscount = true;

          for (let item of sponsoredUserDiscounts) {
            let date;
            if (item?.activeTermDays) {
              date = new Date();
              date.setDate(date.getDate() + item?.activeTermDays);
            }
            availableSponsoredUserDiscounts.push(
              { 
                alias: discountClub?.name ? discountClub?.name : item?.name,
                grantedBy_id: user_id,
                sponsoredUserDiscount_id: item?._id,
                status: item?.status || "active",
                appliedOn: new Date().toISOString().substr(0, 10),
                discountEndDate: date ? date.toISOString().substr(0, 10) : "",
                networkCode_id: networkCodeDoc?._id
              }
            );
          }
          availableSponsoredUserDiscounts[0]['applied'] = true;

          // create accountDiscount detail document for the registered user
          this.afs.collection('accountDiscounts').doc(user_id).set({
            _id: user_id,
            availableSponsoredUserDiscounts: availableSponsoredUserDiscounts,
            totalSponsoredUserDiscounts: totalSponsoredUserDiscounts,
            newSponsoredUserDiscount: newSponsoredUserDiscount,
            usedNetworkCodes: [networkCodeDoc?._id],
            dateCreated : firebase.firestore.FieldValue.serverTimestamp(),
            network_ids: networkCodeDoc?.network_id ? [networkCodeDoc?.network_id] : []
          });
          this.networkCodeService.update(networkCodeDoc?._id, { codeUsedCount: networkCodeDoc?.codeUsedCount ? (networkCodeDoc?.codeUsedCount + 1) : 1 });
        }
      }
    }

    return { availableSponsoredUserDiscounts, availableSelfPayDiscounts };
  }

  /**
   * Signs a user in with a custom token
   */
  tokenSignIn(token): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.signInWithCustomToken(token).then(user => {
        if (user) {
          resolve(user.user.emailVerified);
        }
      }).catch((err) => {
        console.error('Error', err);
        reject(err);
      })
    })
  }

}
