<footer class="bg-primary footer text-{{ companyData?.textColor }}">
    <!-- <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutAlign="center center" class="border-bottom-mute subscribe-block theme-container">
        <div fxFlex="100" fxFlex.gt-sm="40" fxLayout="column" fxLayoutAlign="center center"> 
            <h1 class="fw-500">Subscribe our Newsletter</h1>
            <h3 class="fw-300 secondary-color">Stay up to date with our latest new and products</h3>
        </div>
        <form method="get" class="subscribe-search-form" fxLayout="row" fxFlex="100" fxFlex.gt-sm="42.4" ngClass.sm="mt-2" ngClass.xs="mt-2">
            <input type="text" placeholder="Your email address..." fxFlex>
            <button mat-raised-button color="accent" (click)="subscribe()" type="button" class="mat-elevation-z0 text-muted">Subscribe</button>
        </form>
    </div> -->
    <div fxLayout="row wrap" fxLayoutAlign="space-between" class="py-3 border-bottom-mute theme-container">
        <div *ngIf="companyData?.usefulLinks?.length && companyData.usefulLinks[0].name" fxFlex="100" fxFlex.gt-sm="20" fxFlex.sm="40">
          <h3 class="col-title">USEFUL LINKS</h3>
          <p *ngFor="let item of companyData.usefulLinks; let i = index" class="{{ i > 0 ? 'mt-1' : 'mt-2' }}">
            <a *ngIf="item?.url; else showSpan" class="link secondary-color" [href]="item.url">{{ item.name }}</a>
            <ng-template #showSpan><span>{{ item.name }}</span></ng-template>
          </p>
        </div>
        <div *ngIf="companyData" fxFlex="100" fxFlex.gt-sm="30" fxFlex.sm="60" ngClass.xs="mt-2" class="contact-information">
            <h3 *ngIf="showContact()" class="col-title">CONTACT INFORMATION</h3>
            <p *ngIf="companyData.contactAddress" fxLayout="row" fxLayoutAlign="start center" class="mt-2 secondary-color">
                <mat-icon>location_on</mat-icon>
                <span>{{companyData.contactAddress}}</span>
            </p>
            <p *ngIf="companyData.contactPhone" fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                <mat-icon>call</mat-icon>
                <span><a [href]="contactPhoneHref()">{{companyData.contactPhone}}</a></span>
            </p>
            <p *ngIf="companyData.contactEmail" fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                <mat-icon>mail_outline</mat-icon>
                <span><a [href]="contactEmailHref()">{{companyData.contactEmail}}</a></span>
            </p>
            <!-- <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                <mat-icon>schedule</mat-icon>
                <span>Mon - Sun / 9:00AM - 8:00PM</span>
            </p>
            <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                <mat-icon>directions</mat-icon>
                <a href="javascript:void(0);" class="link secondary-color">Get directions</a>
            </p>
            <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                <mat-icon>directions_bus</mat-icon>
                <span>Routes to us</span>
            </p> -->
            <ng-container *ngIf="companyData?.additionalInformation?.length && companyData.additionalInformation[0].name">
              <p *ngFor="let item of companyData.additionalInformation; let i = index" class="{{ i > 0 ? 'mt-1' : 'mt-2' }}">
                <a *ngIf="item?.url; else showSpan" class="link secondary-color" [href]="item.url">{{ item.name }}</a>
                <ng-template #showSpan><span>{{ item.name }}</span></ng-template>
              </p>
            </ng-container>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="50" ngClass.sm="mt-2" ngClass.xs="mt-2">

            <!-- <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [mapTypeControl]="true">
                <agm-marker [latitude]="lat" [longitude]="lng" [markerDraggable]="true"></agm-marker>
            </agm-map> -->
        </div>
    </div>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center"
        class="copyright secondary-color theme-container">
        <p ngClass.xs="mt-1">©2023 Amptify™ All Rights Reserved</p>
        <p>Designed & Developed by <a mat-button href="http://amptify.com/" target="_blank">Amptify</a></p>
    </div>
</footer>