/**
 * Service for interacting with accounts in Firestore, extends BaseFirestoreService
 * This is in conjunction with the auth service not replacing it
 *
 * @todo type an account
 * @since 0.0.1
 */

import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { ApiFirestoreService } from '@app/_core/services/api-firestore/api-firestore.service';
import { ProductReveiw } from '@app/_core/models/firestore/products/product-review.model';

@Injectable({
  providedIn: 'root'
})
export class ProductReviewService extends ApiFirestoreService<ProductReveiw> {
  constructor(afs: AngularFirestore,
              private analytics: AngularFireAnalytics) {
  	super('productReviews', afs);
  }
}
