import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { QueryVars } from '@app/_core/models';
import { NetworkCode } from '@app/_core/models/firestore/discounts-networks';

import { ApiFirestoreService } from '@app/_core/services/api-firestore/api-firestore.service';

@Injectable({
    providedIn: 'root'
})
export class NetworkCodeService extends ApiFirestoreService<NetworkCode> {

    constructor(afs: AngularFirestore) {
        super('networkCodes', afs);
    }

    /**
   * Get the join link document to help a new user connect to a group
   *
   * @since 0.0.1
   */
  async getCodeData(joinCode: string): Promise<NetworkCode> {
    let codeQuery: QueryVars = {
      where: [{field: 'code', comparator: '==', value: joinCode}]
    }

    const res = await this.getQueryData(codeQuery);

    if (res?.length)
      return res[0];
    else
      return null;
  }

}
