import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { AppService } from '@app/app.service';
import { Product, QueryVars } from '@app/_core/models';
import { ProductService } from '@app/_core/services/firestore';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-related-products-dialog',
  templateUrl: './related-products-dialog.component.html',
  styleUrls: ['./related-products-dialog.component.scss']
})
export class RelatedProductsDialogComponent implements OnInit {

  public products: Array<Product> = [];
  public viewCol: number = 25;
  public page: number = 1;
  public count = 12;
  public totalCount: number = 0;
  private lastPagesRefs = {};
  private sort: Sort;
  public relatedProducts: any[] = [];

  constructor(public dialogRef: MatDialogRef<RelatedProductsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public appService: AppService,
    private productService: ProductService,
    public snackBar: MatSnackBar,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.relatedProducts = this.data || [];
    this.getAllProducts();
  }

  private createAllFilters(count?: number) {
    const condition: QueryVars = {};
    if (count) {
      condition.limit = count;
      const lastRef = this.lastPagesRefs[this.page]
      if (!!lastRef) {
        condition.startAfter = lastRef;
      }
    };

    if (this.sort) {
      condition.dir = this.sort.direction;
      condition.order = this.sort.active;
    };

    return condition;
  }

  public async getTotalCountProducts() {
    const products = await this.productService.getQueryData(this.createAllFilters()) || [];
    this.totalCount = products.length;
  }

  public async getAllProducts() {
    this.spinner.show();
    await this.getTotalCountProducts();
    const { items = [], lastRef } = await this.productService.getPagination(this.createAllFilters(this.count)) || {};
    this.products = items;
    this.lastPagesRefs[this.page + 1] = lastRef;
    this.spinner.hide();
  }

  public onPageChanged(event: any) {
    this.page = event.pageIndex + 1;
    this.count = event.pageSize;
    this.getAllProducts();
    window.scrollTo(0, 0);
  }

  public onImgError(event: any) {
    event.target.src = 'https://via.placeholder.com/1050?text=No Image'
  }

  public selectRelatedProduct(value: boolean, product: Product) {
    if (value) {
      this.relatedProducts.push({ name: product.name, product_id: product._id });
    } else {
      this.relatedProducts = this.relatedProducts.filter(item => item.product_id !== product._id);
    }
  }

  public isCheckValue(product: Product) {
    const isCheck = this.relatedProducts.find(item => item.product_id === product._id);
    return !!isCheck;
  }

  onConfirm(): void {
    this.dialogRef.close(this.relatedProducts);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
