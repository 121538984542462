

export function getIndexBy(array: Array<{}>, { name, value }): number {
  for (let i = 0; i < array.length; i++) {
    if (array[i][name] === value) {
      return i;
    }
  }
  return -1;
}

// export function getLastSeenText(time){
//   let text = "";
//   let diff = Math.floor((moment.utc().valueOf() - moment.utc(time).valueOf()) / 1000);
//   if(diff > 0){
//       text = diff > 1 ? String(diff) + " seconds ago" : String(diff) + " second ago";
//       if(diff > 60){
//           diff = Math.floor(diff / 60);
//           text = diff > 1 ? String(diff) + " minutes ago" : String(diff) + " minute ago";
//           if(diff > 60){
//               diff = Math.floor(diff / 60);
//               text = diff > 1 ? String(diff) + " hours ago" : String(diff) + " hour ago";
//               if(diff > 24){
//                   diff = Math.floor(diff / 24);
//                   text = diff > 1 ? String(diff) + " days ago" : String(diff) + " day ago";
//               }
//           }
//       }
//   }
//   return text;
// }

export function convertTime12to24(time12h: any) {
  const [time, modifier] = time12h.split(' ');

  let [hours, minutes] = time.split(':');

  if (hours === '12') {
    hours = '00';
  }

  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}`;
}

export function convertTime24to12(time24h: any) {
  let [hours, minutes] = time24h.split(':'),
    modifier = hours >= 12 ? 'PM' : 'AM';

  if (hours > 12)
    hours -= 12;

  return hours + ':' + minutes + ' ' + modifier;
}

export function timeIsFuture(time24h: string) {
  let [hours, minutes] = time24h.split(':');
  let cstTime = new Date().toLocaleString("en-GB", { timeZone: 'CST', hour12: false },);
  let timeNow = cstTime.slice(-8);
  let nowHours = timeNow.slice(0, 2)
  let nowMinutes = timeNow.slice(3, 5)
  if (parseInt(hours) > parseInt(nowHours)) {
    return true;
  }
  else if (parseInt(hours) == parseInt(nowHours) && parseInt(minutes) > parseInt(nowMinutes)) {
    return true;
  }
  else {
    return false;
  }
}

export function slugify(string) {
  return string.toLowerCase().split(' ').join('_');
}

/**
 * Get the difference between two dates
 *
 * @since 0.1.16
 */
export function dayDiff(date1, date2 = null) {
  const a = new Date(date1),
    b = date2 ? new Date(date2) : new Date(),
    _MS_PER_DAY = 1000 * 60 * 60 * 24;

  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.ceil((utc2 - utc1) / _MS_PER_DAY);
}

/**
 * Converts date created from mat timepicker to an ISO date without timezone
 *
 * @since 0.2.2
 */
export function convertToISO(pickerDate: string): string {
  var date = new Date(pickerDate),
    year = date.getFullYear(),
    month = date.getMonth() + 1 > 9 ? String(date.getMonth() + 1) : '0' + String(date.getMonth() + 1),
    day = date.getDate() > 9 ? String(date.getDate()) : '0' + String(date.getDate());


  return String(year) + '-' + month + '-' + day;
}

/**
 * Convert the ISO date to local date adjusting for timezone
 *
 * @since 0.2.2
 */
export function convertISOtoDate(isoDate: string): Date {
  //console.log(typeof(isoDate));
  if (typeof (isoDate) == 'string' && isoDate.indexOf('-') > -1) {
    const [year, month, day] = isoDate.split('-');
    let date = new Date(Date.UTC(parseInt(year), parseInt(month) - 1, parseInt(day), 0, 0, 0));

    date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
    return date;
  } else if (typeof (isoDate) == 'object') {
    // This is the old date, it's a firestore timestamp most likely
    return;// (isoDate as any).toDate();
  }

}

/**
 * Helper to create a random code with a specific pattern
 *
 * @since 0.8.7
 */
export function generateCode(pattern: string) {
  var d = new Date().getTime();
  if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
    d += performance.now(); //use high-precision timer if available
  }
  return pattern.replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16).toUpperCase();
  });
}

/**
 * Delays with a resolving promise
 */
export function delayPromise(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * Check if two arrays are equal
 *
 * @since 1.0.6
 */
export function arraysEqual(_arr1: any[], _arr2: any[]) {
  if (_arr1 === _arr2) return true;
  if (_arr1 == null || _arr2 == null) return false;
  if (_arr1.length !== _arr2.length) return false;

  // .concat() is used so the original arrays are unaffected
  const arr1 = _arr1.concat().sort();
  const arr2 = _arr2.concat().sort();

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
}

/**
 * Simple regex email validator of "anystring@anystring.anystring"
 * 
 * @since 1.0.10
 */
export function validateEmail(email: string) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export function getOrderLabelByValue(value: string) {
  const words = value?.split("-");
  return words?.map((word) => {
    return word?.[0]?.toUpperCase() + word?.substring(1);
  }).join(" ");
};