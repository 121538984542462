/**
 * Service for interacting with accounts in Firestore, extends BaseFirestoreService
 * This is in conjunction with the auth service not replacing it
 *
 * @todo type an account
 * @since 0.0.1
 */

import { Injectable } from '@angular/core';

import * as firebase from 'firebase/compat/app';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

import { BehaviorSubject, Subscription, Observable } from 'rxjs';
import {first, map, takeUntil} from 'rxjs/operators';

import { ROLES } from '@app/_core/constants/app-constants';

import { Account } from '@app/_core/models/firestore';
import { QueryVars } from '@app/_core/models';

import { ApiFirestoreService } from '@app/_core/services/api-firestore/api-firestore.service';

import { ErrorHandlerService } from "@app/_core/services/error-handler.service";

@Injectable({
  providedIn: 'root'
})
export class AccountService extends ApiFirestoreService<Account> {
  private accountSub: Subscription;

	private accountInfo = new BehaviorSubject<Account>(null);
	currentAccountInfo = this.accountInfo.asObservable();
	accountLoaded: boolean;

  isAccountLoaded = new BehaviorSubject<boolean>(false);

  constructor(afs: AngularFirestore,
              private analytics: AngularFireAnalytics) {
  	super('accounts', afs);
  }

  /**
   * Quick check if the current account is locked
   *
   * @since 0.1.6
   */
  currentAccountLocked(): boolean {
    const accountInfo = this.accountInfo.getValue();
    return accountInfo?.locked;
  }

  /**
   * Initialize the user account and watch it, to be subscribed to around the app
   *
   * @since 0.0.1
   */
  async initAccountInfo(uid) {
  	if (!this.accountLoaded && uid) {
      // Make sure the data is updated from the server not persistence
      const accountData = await this.get(uid);

      // If the user logs in and they were inactive then they should be reactivated
      if (accountData?.accountStatus == 'inactive' || (accountData && !accountData.accountStatus)) await this.update(uid, {accountStatus: 'active'});

  		this.accountSub = this.watchLive(uid).subscribe(snapshot => {
        console.log(snapshot.fromCache ? 'Account data loaded from cached persistence' : 'Live account data');
        let accountInfo = {
          _fromCache: snapshot.fromCache,
          ...snapshot.data
        };

        this.accountInfo.next(accountInfo);
        if (accountInfo) {
          this.accountLoaded = true;
          this.isAccountLoaded.next(true);
          //this.authorizationService.loadUserPermissions(accountInfo);

          //this.accountStatistics.seenToday(accountInfo._id);
        }

  			
  		}, err => {
        ErrorHandlerService.catch('[AccountService] initAccountInfo', err);
      });
  	}

    
  }

  /**
   * Unsubscribe from the user account document and clear the behavior subject
   *
   * @since 0.0.1
   */
  doLogout() {
    if (this.accountSub)
      this.accountSub.unsubscribe();

    //this.authorizationService.unLoadUserPermissions();
    if (this.accountInfo) {
      const userData = this.accountInfo.getValue();
      this.analytics.logEvent('logout', {
        account_id: userData?._id || '',
        email: userData?.email || ''
      });
      this.accountInfo.next(null);
    }

  	this.accountLoaded = false;
    if (this.isAccountLoaded)
      this.isAccountLoaded.next(false);

    return;
  }


  /**
   * Paginate the account collection
   * @param lastAccount_id: the id of the last item paged
   *
   * @since 0.0.0
   */
  getAllUsers(lastAccount_id: string = null) {
    let query: QueryVars = {
      order: 'rating'
    };
    if (!lastAccount_id) {
      return this.paginate(20, query);
    } else {
      return this.page(20, lastAccount_id, query);
    }
  }

  /**
   * Get all users with specified role
   *
   * @since 0.0.0
   */
  getUsersByRole(role): Promise<Account[]> {
    return new Promise<Account[]>((resolve, reject) => {
      const query: QueryVars = {
        where: [{field: 'roles', comparator: 'array-contains', value: role}]
      };

      this.getQuery(query).pipe(first()).toPromise().then(accountSnapshots => {
        let accounts;

        if (accountSnapshots && accountSnapshots.size) {
          accounts = [];

          accountSnapshots.docs.forEach(doc => {
            accounts.push(doc.data());
          });
        }

        resolve(accounts);
      }, err => {
        ErrorHandlerService.catch('[AccountService] getUsersByRole', err);
        reject(err);
      })
    });
  }

  public getAccountInfo(){
    return this.accountInfo;
  }

  /**
   * Helper to see what role a user has
   *
   * @since 0.1.0
   */
  public checkRole(account: Account = null): 'super' | 'coach-manager' | 'coach' | 'none' {
    if (!account) account = this.accountInfo.getValue();

    if (account && account.roles) {
      if (account.roles.includes(ROLES.SUPER_ADMIN)) {
        return 'super';
      } else if (account.roles.includes(ROLES.GROUP_COACH_MANAGER)) {
        return 'coach-manager';
      } else if (account.roles.includes(ROLES.GROUP_COACH)) {
        return 'coach';
      }
    }

    return 'none';
  }

  public async awaitRole(account: Account = null): Promise<'super' | 'coach-manager' | 'coach' | 'none'> {//'active-trial' | 'active-notrial' | 'active' | 'canceled' {
    return new Promise((resolve, reject) => {
      if (account) {
        resolve(this.checkRole(account));
      } else if (this.isAccountLoaded.getValue()) {
        account = this.accountInfo.getValue();

        resolve(this.checkRole(account));
      } else {
        const subscriber = this.isAccountLoaded.subscribe(loaded => {
          if (loaded) {
            account = this.accountInfo.getValue();
            resolve(this.checkRole(account));
            subscriber.unsubscribe();
          }
        })
      }
    })
  }

  /**
   * Helper to check a users subscription status
   *
   * @since 1.1.5
   */
  public checkSubscription(account: Account = null): Promise<string> {//'active-trial' | 'active-notrial' | 'active' | 'canceled' {
    return new Promise((resolve, reject) => {
      if (account) {
        resolve(account.subscriptionStatus);
      }else if (this.isAccountLoaded.getValue()) {
        account = this.accountInfo.getValue();

        resolve(account.subscriptionStatus);
      } else {
        const subscriber = this.isAccountLoaded.subscribe(loaded => {
          if (loaded) {
            account = this.accountInfo.getValue();
            resolve(account.subscriptionStatus);
            subscriber.unsubscribe();
          }
        })
      }
    })
  }
}
