import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/compat/firestore';

import { ApiFirestoreService } from '@app/_core/services/api-firestore/api-firestore.service';
import { AccountBilling } from '@app/_core/models/firestore';

@Injectable({
  providedIn: 'root'
})
export class AccountBillingService extends ApiFirestoreService<AccountBilling> {

  constructor(afs: AngularFirestore) {
  	super('accountBillings', afs);
  }
}
