import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-file-drop',
  templateUrl: './file-drop.component.html',
  styleUrls: ['./file-drop.component.scss']
})
export class FileDropComponent implements OnInit {
  @ViewChild('uploadArea') uploadArea: ElementRef;

  @Input() buttonText: string = 'Upload File';
  @Input() alternateText: string = '(or) drag and drop files here'
  @Input() fileTypes: any;

  @Output() files = new EventEmitter<any>();

  errorMessage: string;

  constructor() { }

  ngOnInit(): void {
  }

  onDragOver(event: Event) {
    event.preventDefault();
    this.uploadArea.nativeElement.className = "upload-area active"
  }

  onDragLeave(event: Event) {
    this.uploadArea.nativeElement.className = "upload-area"
  }

  async onDropSuccess(event: Event) {
    event.preventDefault();

    let files = [];
    const items = (event as any).dataTransfer.items;

    for (const item of items) {
      if (item.kind === 'file') {
        const entry = item.webkitGetAsEntry();

        if (entry.isFile) {
          const file = item.getAsFile();
          files.push(file);
        } else if (entry.isDirectory) {
          const entries = await this.parseDirectoryEntry(entry);
          for (const entryItem of entries as any[]) {
            if (entryItem.isFile) {
              const file: any = await this.parseFileEntry(entryItem);
              files.push(file);
            }
          }
        }
      }
    }

    if (files?.length) {
      this.files.emit(files);
    }

    this.uploadArea.nativeElement.className = "upload-area";
  }

  onChange(event: Event) {

  }

  /**
   * Returns the file as a promise
   */
  private parseFileEntry(fileEntry) {
    return new Promise((resolve, reject) => {
      fileEntry.file(
        file => {
          resolve(file);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  /**
   * If a directory is dropped this will parse and return the files as file entries
   */
  private parseDirectoryEntry(directoryEntry) {
    const directoryReader = directoryEntry.createReader();
    return new Promise((resolve, reject) => {
      directoryReader.readEntries(
        entries => {
          resolve(entries);
        },
        err => {
          reject(err);
        }
      );
    });
  }

}
